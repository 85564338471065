import React, { useEffect } from "react";
import { FormControl, InputGroup } from "react-bootstrap/cjs";
import { CommonPropertyProps } from "../../pages/model/ModelCreatePage";
import styles from "./styles.module.css";

interface StringPropertyFieldProps extends CommonPropertyProps<string> {
  unit?: string,
  maxLength?: number,
}

export default function StringProperty(props: StringPropertyFieldProps): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    return (
        <div>
            {props.optional
                ? (
                    <input
                        type="checkbox"
                        className={styles.optionalCheckBox}
                        checked={props.valuePresented}
                        onChange={(e) => props.valuePresentedChangeCallback(e.target.checked)}
                    />
                )
                : <></>
            }
            <label htmlFor={props.name}>{props.title}:</label>
            {props.optional && !props.valuePresented
                ? <></>
                : (
                    <InputGroup className="mb-3">
                        <FormControl
                            id={props.name}
                            value={props.value}
                            disabled={props.readonly}
                            maxLength={props.maxLength}
                            onChange={(e) => props.readonly ? false : props.valueChangeCallback(e.target.value)}
                            isInvalid={!props.valueValid}
                            isValid={props.showValid && props.valueValid}
                        />
                        {props.unit
                            ? <InputGroup.Append><InputGroup.Text>{props.unit}</InputGroup.Text></InputGroup.Append>
                            : <></>
                        }
                    </InputGroup>
                )
            }
        </div>
    );
}
