import React from "react";
import {Observer, useLocalObservable} from "mobx-react";
import {AppRouter} from "./components/navigation/router/AppRouter";
import {getSession} from "./utils/session";
import {API, ModelDefinition} from "./api/API";
import {ErrorPane} from "./components/errorPane/ErrorPane";
import {runInAction} from "mobx";

export interface GlobalState {
    session: string | null;
}

interface AppProps {
    api: API;
    models: ModelDefinition[];
}


export default function App(props: AppProps): JSX.Element {
    const state = useLocalObservable(() => ({
        session: getSession(),
        errors: [] as Error[]
    }));

    function errorHandler(e: Error) {
        runInAction(() => {
            state.errors.unshift(e);
            setTimeout(() => runInAction(() => {
                state.errors.pop();
            }), 3000);
        });
    }

    return <Observer>{() => {
        props.api.setSession(state.session);
        return (<><AppRouter globalState={state}
            models={props.models}
            api={props.api}
            errorHandler={errorHandler}/><ErrorPane errors={state.errors}/></>);
    }}</Observer>;
}
