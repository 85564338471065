import React, {useEffect} from "react";
import { FormControl, InputGroup } from "react-bootstrap/cjs";
import { CommonPropertyProps } from "../../pages/model/ModelCreatePage";
import styles from "./index.module.css";

function dateToISOString(date: Date): string {
    return `${date.getFullYear()}-${
        `${date.getMonth() + 1}`.padStart(2, "0")}-${
        `${date.getDate()}`.padStart(2, "0")}T${
        `${date.getHours()}`.padStart(2, "0")}:${
        `${date.getMinutes()}`.padStart(2, "0")}`;
}

export function DateTimeProperty(props: CommonPropertyProps<Date>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(true);
    });

    return <div>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> :
            <InputGroup className="mb-3">
                <FormControl type={"datetime-local"} id={props.name} defaultValue={dateToISOString(props.value)}
                    disabled={props.readonly} isInvalid={!props.valueValid}
                    isValid={props.showValid && props.valueValid}
                    onChange={(e) => props.readonly ? false : props.valueChangeCallback(new Date(e.target.value))}/>
            </InputGroup>
        }
    </div>;
}
