// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ControlProps } from "../pages/model/ModelCreatePage";
import { ProductData } from "../../index";
import ListGroup from "react-bootstrap/cjs/ListGroup";
import { FormControl, InputGroup } from "react-bootstrap/cjs";
import Spinner from "react-bootstrap/cjs/Spinner";
import { CloseButton } from "react-bootstrap";
import Button from "react-bootstrap/cjs/Button";


type ProductCategory = ProductData["subCategory"] | ProductData["subSubCategory"] | ProductData["category"]

const ProductsCategory = (props: ControlProps): JSX.Element => {
    const [product, setProduct] = useState<ProductData | undefined>();
    const [data, setData] = useState<ProductCategory>([]);
    const [inputValue, setInputValue] = useState<{ id: string, name: string }>();
    const [inputValues, setInputValues] = useState();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const id = props.id as string;
    useEffect(() => {
        getProduct();
        getInputValues();
    }, []);

    const getProduct =  async () => {
        if(id) {
            const response = await props.model.endpoints.fetchSingle(id);
            const data: ProductData = response as any;
            const key = props.property.name as "category" | "subCategory" | "subSubCategory";
            const categoryData =  {
                category: data.category,
                subCategory: data.subCategory,
                subSubCategory: data.subSubCategory,
            };
            if (data) {
                setProduct(data);
                setData(categoryData[key]);
            }
        }
    };

    const getInputValues = async () => {
        const values = await props.models
            .find(item => {
                return  item.name === props.property.originalModelPropertyName;
            })?.endpoints.fetchMany();
        if (values) {
            setInputValues(values.items);
        }
    };

    const onDeleteItem = (idCategory) => async () => {
        try {
            await props.api.callApi("delete", `/api/admin/${props.property.name}/${idCategory}`,  { productId: id});
            setData(prevState => prevState.filter(item => item.id !== idCategory));
        } catch (e) {
            console.log(e);
        }
    };

    const onChange = (e) => {
        const [id, name] = e.target.value.split(",");
        setInputValue({ id, name });
    };

    const onAdd = async () => {
        if (!data.find(item => item?.id === inputValue?.id)) {
            const response = await updateProducts();
            if (response.ok) {
                setData(prevState => [...prevState, inputValue]);
            }
        }
    };

    const updateProducts = async () => {
        try {
            const payload = {
                name: product?.name,
                slug: product?.slug,
                description: product?.description,
                price: product?.price,
                saleId: product?.saleId,
                priorityCategoryId: product?.priorityCategoryId,
                disabled: product?.disabled,
                properties: product?.properties,
                [`${props.property.name}Id`]: inputValue.id,
            };
            const response = await props.model.endpoints.update(id, payload);
            return { ok: true };
        } catch (e) {
            console.log(e);
            return { ok: false };
        }
    };

    return <div>
        <p>{props.property.title}</p>
        <InputGroup className="mb-3">
            {data ?
                <FormControl disabled={!id} onChange={onChange} as={"select"}>
                    <option>Выберите значение</option>
                    {inputValues?.map(item => <option value={`${item.id},${item.name}`} key={item.id}>{item.name}</option>)}
                </FormControl>
                : <Spinner animation="border" role="status">
                    <span className="sr-only">Загрузка...</span>
                </Spinner>
            }
            <Button onClick={onAdd} variant="primary">Добавить</Button>
        </InputGroup>
        <ListGroup>
            {data.map(item => <ListGroup.Item key={item.id}>{item.name} <CloseButton onClick={onDeleteItem(item.id)} /></ListGroup.Item>)}
        </ListGroup>
    </div>;
};

export default ProductsCategory;
