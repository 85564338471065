import React from "react";
import {Redirect, Route, Router, Switch, useLocation} from "react-router-dom";
import {createBrowserHistory} from "history";
import {GlobalState} from "../../../App";
import {LoginPage} from "../../pages/login/LoginPage";
import {HomePage} from "../../pages/home/HomePage";
import {Observer} from "mobx-react";
import {API, CreatableModel, ModelDefinition, UpdatableModel} from "../../../api/API";
import {ModelListPage} from "../../pages/model/ModelListPage";
import {ModelCreatePage} from "../../pages/model/ModelCreatePage";
import {ModelUpdatePage} from "../../pages/model/ModelUpdatePage";
import {SettingsPage} from "../../pages/settings/SettingsPage";

export interface AppRouterProps {
    globalState: GlobalState;
    models: ModelDefinition[];
    api: API
    errorHandler: (error: Error) => void;
}

function InnerRouter(props: AppRouterProps): JSX.Element {
    const location = useLocation();

    return <Observer>{() => {
        if (!props.globalState.session && location.pathname !== "/login") {
            return <Redirect to={`/login#${location.pathname}`}/>;
        }

        return (<Switch>
            <Route exact={true} path={"/login"}>
                <LoginPage globalState={props.globalState} authHandler={(username, password) =>
                    props.api.auth(username, password)} errorHandler={props.errorHandler}/>
            </Route>
            {props.models.map(model => (<Route key={`route-${model.name}`} path={`/${model.name}`} exact={true}>
                <ModelListPage key={`model-page-${model.name}`} {...props} model={model}/>
            </Route>))}
            {props.models.filter(model => model.operations.create).map(model =>
                <Route key={`route-${model.name}-create`} path={`/${model.name}/create/`} exact={true}
                    render={() => {
                        const createPage = (model as CreatableModel).customPages?.create;
                        return createPage ? createPage({
                            ...props,
                            model
                        }) :
                            <ModelCreatePage key={`model-create-page-${model.name}`} {...props} model={model}/>;
                    }}/>)}
            {props.models.filter(model => model.operations.update).map(model => (
                <Route key={`route-${model.name}-update`} path={`/${model.name}/:id`} exact={true}
                    render={(routeProps) => {
                        const updatePage = (model as UpdatableModel).customPages?.update;
                        return updatePage ? updatePage({
                            ...props,
                            model,
                            id: routeProps.match.params.id ?? ""
                        }) :
                            <ModelUpdatePage key={`model-update-page-${model.name}`} {...props} model={model}
                                id={routeProps.match.params.id ?? ""}/>;
                    }}/>))}
            <Route exact={true} path="/settings">
                <SettingsPage {...props} />
            </Route>
            <Route exact={true} path="/">
                <HomePage {...props} />
            </Route>
        </Switch>);
    }}</Observer>;
}

const history = createBrowserHistory();

export function AppRouter(props: AppRouterProps): JSX.Element {
    return (<>
        <Router history={history}>
            <InnerRouter {...props}/>
        </Router>
    </>);
}
