import {Alert} from "react-bootstrap";
import React from "react";
import {Observer} from "mobx-react";
import {errorToString} from "../../utils/errors";

interface ErrorPaneComponentProps {
    errors: Error[]
}

export function ErrorPane(props: ErrorPaneComponentProps): JSX.Element {
    return <Observer>{() => (
        <div style={{
            position: "fixed",
            bottom: 0,
            right: "20px",
            zIndex: 100000
        }}>
            {props.errors.map((error, index) => (
                <Alert key={`error-${props.errors.length - index}`} variant="danger">{errorToString(error)}</Alert>
            ))}
        </div>)}</Observer>;
}
