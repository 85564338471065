import {Observer, useLocalObservable} from "mobx-react";
import React from "react";
import Form from "react-bootstrap/esm/Form";
import {Redirect, useLocation} from "react-router-dom";
import {GlobalState} from "../../../App";
import Button from "react-bootstrap/cjs/Button";
import {setSession} from "../../../utils/session";
import Card from "react-bootstrap/cjs/Card";
import styles from "./LoginPage.module.css";
import {runInAction} from "mobx";

interface LoginPageProps {
    globalState: GlobalState
    authHandler: (username: string, password: string) => Promise<string>
    errorHandler: (error: Error) => void
}

export function LoginPage(props: LoginPageProps): JSX.Element {
    const location = useLocation();
    const state = useLocalObservable(() => ({
        isLogging: false,
        username: "",
        password: ""
    }));

    if (props.globalState.session) {
        return <Redirect to={!location.hash.startsWith("#/") || location.hash === "#/login" ?
            "/" : location.hash.substr(1)}/>;
    }

    function handleLogin() {
        runInAction(() => {
            state.isLogging = true;
            props.authHandler(state.username, state.password).then(token => {
                runInAction(() => {
                    state.isLogging = false;
                    props.globalState.session = setSession(token);
                });
            }).catch(e => {
                runInAction(() => {
                    state.isLogging = false;
                });
                props.errorHandler(e);
            });
        });
    }

    return <Observer>{() => <>
        <div className={styles.loginBlock}>
            <Card className={styles.loginCard}>
                <Card.Body>
                    <Card.Title>Вход в админпанель</Card.Title>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                    }}>
                        <Form.Group controlId="username">
                            <Form.Label>Логин</Form.Label>
                            <Form.Control
                                autoFocus
                                type="text"
                                value={state.username}
                                onChange={(e) => runInAction(() => state.username = e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                value={state.password}
                                onChange={(e) => runInAction(() => state.password = e.target.value)}
                                autoComplete={"password"}
                            />
                        </Form.Group>
                        <Button block size="lg" type="submit" disabled={state.isLogging}>
                            {state.isLogging ? "Вход..." : "Войти"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    </>}</Observer>;
}
