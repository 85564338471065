import {useHistory} from "react-router-dom";
import {Observer, useLocalObservable} from "mobx-react";
import Button from "react-bootstrap/cjs/Button";
import styles from "./ContentUpdatePage.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import React from "react";
import Spinner from "react-bootstrap/cjs/Spinner";
import {runInAction} from "mobx";
import {CustomUpdatePageProps, ModelPropertyDefinition, UpdatableModel} from "../../api/API";
import {LoggedPage} from "../loggedPage/LoggedPage";
import {getControl} from "../pages/model/ModelCreatePage";
import {MAIN_CURRENCY_SIGN} from "../../index";

type ContentTypeClass = "visualEditor" | "string" | "number" | "file" | "image" | "price";

interface ContentType {
    title: string,
    crudTitle: string,
    contentType: ContentTypeClass
}

const CONTENT_TYPES: Record<string, ContentType> = {
    "delivery-banner-image-url": {
        title: "Баннер оптовых поставок",
        crudTitle: "баннера оптовых поставок",
        contentType: "image"
    },
    "prices-pdf": {
        title: "PDF-файл с ценами",
        crudTitle: "PDF-файла с ценами",
        contentType: "file"
    },
    "price-posevnoy-gason": {
        title: "Цена услуги посевного газона",
        crudTitle: "цены услуги посевного газона",
        contentType: "price"
    },
    "price-rullonuy-gason": {
        title: "Цена услуги рулонного газона",
        crudTitle: "цены услуги рулонного газона",
        contentType: "price"
    },
    "about-company": {
        title: "Страница \"О компании\"",
        crudTitle: "страницы \"О компании\"",
        contentType: "visualEditor"
    }
};

export function contentNameMapper(name: string): string {
    return CONTENT_TYPES[name] ? CONTENT_TYPES[name].title : name;
}

export function ContentUpdatePage(props: CustomUpdatePageProps): JSX.Element {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        value: undefined as string | undefined,
        valid: false,
        isUpdating: false,
        isLoading: false
    }));

    return <Observer>{() => {
        if (state.value === undefined && !state.isLoading) {
            state.isLoading = true;
            props.model.endpoints.fetchSingle(props.id).then((response: any) => {
                runInAction(() => {
                    state.value = response.data;
                    state.isLoading = false;
                });
            }).catch(e => {
                props.errorHandler(e);
                history.push(`/${props.model.name}`);
                runInAction(() => {
                    state.isLoading = false;
                });
            });
        }
        
        let property: ModelPropertyDefinition;
        
        switch (CONTENT_TYPES[props.id]?.contentType ?? "string") {
        case "visualEditor":
            property = {
                type: "visualEditor",
                name: "data",
                title: "Содержимое"
            };
            break;
        case "string":
            property = {
                type: "string",
                name: "data",
                title: "Содержимое"
            };
            break;
        case "number":
            property = {
                type: "number",
                name: "data",
                title: "Значение"
            };
            break;
        case "price":
            property = {
                type: "number",
                name: "data",
                title: "Цена",
                min: 0,
                unit: MAIN_CURRENCY_SIGN
            };
            break;
        case "file":
            property = {
                type: "file",
                name: "data",
                title: "Файл"
            };
            break;
        case "image":
            property = {
                type: "image",
                name: "data",
                title: "Изображение"
            };
            break;
        default:
            property = {
                type: "string",
                name: "data",
                title: "Содержимое"
            };
        }

        return (<LoggedPage {...props}>
            <div className={"clearfix"}>
                <Button className={`float-left ${styles.backButton}`}
                    onClick={() => history.push(`/${props.model.name}`)}>
                    <FontAwesomeIcon icon={faArrowLeft}/></Button>
                <h2 className={"float-left"}>Редактирование {CONTENT_TYPES[props.id]?.crudTitle ?? props.id}</h2>
            </div>
            <div className={styles.formBody}>
                {state.isLoading ? <div className={"d-flex justify-content-center"}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div> :
                    <div>
                        <div>
                            {
                                getControl({
                                    api: props.api,
                                    entityPropertyGetter: () => { throw new Error(); },
                                    propertyValue: state.value,
                                    valuePresented: true,
                                    valueChangeCallback: (value: unknown) => runInAction(() => state.value = String(value)),
                                    valueValidityCallback: (valid: boolean) => runInAction(() => state.valid = valid),
                                    valueValid: state.valid,
                                    valuePresentedChangeCallback: () => {
                                        /* ignore */
                                    },
                                    property,
                                    model: props.model,
                                    models: props.models,
                                    errorHandler: props.errorHandler,
                                    showValid: true
                                })
                            }
                        </div>
                        <div className={"clearfix"}>
                            <Button variant={"warning"} className={"float-right"} onClick={() => {
                                state.isUpdating = true;
                                (props.model as UpdatableModel).endpoints.update(props.id, {
                                    data: state.value
                                }).then(() => {
                                    state.value = undefined;
                                    state.isUpdating = false;
                                }).catch(e => {
                                    state.isUpdating = false;
                                    props.errorHandler(e);
                                });
                            }}>Сохранить</Button>
                        </div>
                    </div>}
            </div>
        </LoggedPage>);
    }}</Observer>;
}
