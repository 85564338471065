import React from "react";
import {GlobalState} from "../../../App";
import {
    API,
    DefaultModelData,
    DeletableModel,
    ModelDefinition,
    RequestHandlingError,
    SearchableModel
} from "../../../api/API";
import {LoggedPage} from "../../loggedPage/LoggedPage";
import Button from "react-bootstrap/cjs/Button";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Observer, useLocalObservable} from "mobx-react";
import styles from "./ModelListPage.module.css";
import Spinner from "react-bootstrap/cjs/Spinner";
import ListGroup from "react-bootstrap/cjs/ListGroup";
import Pagination from "react-bootstrap/cjs/Pagination";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {useHistory} from "react-router-dom";
import {setSession} from "../../../utils/session";
import {observable, runInAction} from "mobx";
import Card from "react-bootstrap/cjs/Card";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {getControl} from "./ModelCreatePage";

interface ModelListPageProps {
    api: API;
    globalState: GlobalState;
    models: ModelDefinition[];
    model: ModelDefinition;
    errorHandler: (error: Error) => void;
}

const PAGE_SIZE = 20;

interface PaginatorProps {
    totalPages: number;
    currentPage: number;
    pageChangeCallback: (newPage: number) => void;
}

export function Paginator(props: PaginatorProps): JSX.Element {
    const paginatorItems = [];

    for (let page = 0; page < props.totalPages; page++) {
        paginatorItems.push(<Pagination.Item onClick={() => props.pageChangeCallback(page)}
            key={page} active={page === props.currentPage}>
            {page + 1}
        </Pagination.Item>);
    }

    return <Pagination>{paginatorItems}</Pagination>;
}

export function ModelListPage(props: ModelListPageProps): JSX.Element {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        page: 0,
        previousPage: 0,
        currentItems: [] as (unknown & DefaultModelData)[],
        totalItems: null as null | number,
        isLoading: false,
        shouldUseSearch: false,

        searchData: observable.map<string, unknown>({}),
        searchDataValid: observable.map<string, boolean>({}),
        searchDataPresented: observable.map<string, boolean>({}),
        viewSearchData() {
            const model = props.model;
            if (!model.operations.search) {
                return {};
            }
            const resultData: any = {};
            for (const property of (model as SearchableModel).searchProperties) {
                if (!property.optional || (property.optional && this.searchDataPresented.get(property.name))) {
                    resultData[property.name] = this.searchData.get(property.name);
                }
            }
            return resultData;
        }
    }));

    return <Observer>{() => {
        if (props.model.operations.search) {
            for (const property of (props.model as SearchableModel).searchProperties) {
                if (!state.searchDataValid.has(property.name)) {
                    state.searchDataValid.set(property.name, false);
                }
                if (!state.searchDataPresented.has(property.name) && property.optional) {
                    state.searchDataPresented.set(property.name, false);
                }
            }
        }

        if ((state.totalItems === null || state.page !== state.previousPage) && !state.isLoading) {
            runInAction(() => {
                state.isLoading = true;
            });

            const promise = (props.model.operations.search && state.shouldUseSearch) ?
                (props.model as SearchableModel).endpoints.search(state.viewSearchData(), state.page * PAGE_SIZE, PAGE_SIZE)
                : props.model.endpoints.fetchMany(state.page * PAGE_SIZE, PAGE_SIZE);

            promise.then(response => {
                runInAction(() => {
                    state.previousPage = state.page;
                    state.page = Math.min(state.page, Math.max(0, Math.ceil(response.count / PAGE_SIZE) - 1));
                    state.totalItems = response.count;
                    state.currentItems = response.items;
                    state.isLoading = false;
                });
            }).catch(e => {
                props.errorHandler(e);
                if (e instanceof RequestHandlingError) {
                    console.info(e.code);
                    if (e.code === 401) {
                        runInAction(() => {
                            props.globalState.session = setSession(null);
                        });
                    }
                }
                runInAction(() => {
                    state.previousPage = state.page;
                    state.totalItems = 0;
                    state.isLoading = false;
                });
            });
        }

        return (<LoggedPage {...props}>
            <div className={"clearfix"}>
                <h2 className={"float-left"}>{props.model.multipleTitle}</h2>
                <div className={"float-right"}>{props.model.operations.create ?
                    <Button onClick={() => history.push(`/${props.model.name}/create/`)}>
                        <FontAwesomeIcon icon={faPlus}/></Button> : <></>}</div>
            </div>
            {props.model.operations.search ?
                <div className={styles.listBody}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Поиск</Card.Title>
                            <div>
                                {(props.model as SearchableModel).searchProperties.map(property => getControl({
                                    ...props,
                                    entityPropertyGetter: key => state.searchData.get(key),
                                    property,
                                    valueChangeCallback: value => runInAction(() => state.searchData.set(property.name, value)),
                                    valueValidityCallback: valid => runInAction(() => state.searchDataValid.set(property.name, valid)),
                                    propertyValue: state.searchData.get(property.name),
                                    valuePresented: state.searchDataPresented.get(property.name) ?? false,
                                    valueValid: state.searchDataValid.get(property.name) ?? false,
                                    valuePresentedChangeCallback: presented => runInAction(() =>
                                        state.searchDataPresented.set(property.name, presented)),
                                    showValid: false
                                }))}
                            </div>
                            <Button variant={"dark"} style={{width: "100%"}}
                                disabled={state.isLoading ||
                                Array.from(state.searchDataValid.keys()).filter(key => !state.searchDataPresented.has(key)
                                    || state.searchDataPresented.get(key)).map(key => state.searchDataValid.get(key)).indexOf(false) !== -1}
                                onClick={() => runInAction(() => {
                                    state.shouldUseSearch = true;
                                    state.totalItems = null;
                                })}>
                                <FontAwesomeIcon icon={faSearch}/>
                            </Button>
                        </Card.Body>
                    </Card>
                </div> : <></>}
            <div className={styles.listBody}>
                {state.isLoading ? (
                    <div className={"d-flex justify-content-center"}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </Spinner>
                    </div>) : (<div>
                    <div>
                        <ListGroup>
                            {state.currentItems.map((item, index) => {
                                return <ListGroup.Item style={{cursor: "pointer"}} as={"div"} key={index} action={props.model.operations.update}
                                    onClick={() => history.push(`/${props.model.name}/${item.id}`)}>
                                    <div className={"clearfix"}>
                                        <div className={styles.numbering}>{index + 1 + state.page * PAGE_SIZE}.</div>
                                        <div className={"float-left"}>{
                                            props.model.descriptionGenerator(item)
                                        }</div>
                                        <div className={"float-right"}>{
                                            props.model.operations.delete ?
                                                <Button variant={"danger"} size="sm" onClick={(e) => {
                                                    e.stopPropagation();
                                                    (props.model as DeletableModel).endpoints.delete(item.id)
                                                        .then(() => state.totalItems = null)
                                                        .catch(e => {
                                                            props.errorHandler(e);
                                                        });
                                                }}><FontAwesomeIcon
                                                        icon={faTrash}/></Button> : <></>
                                        }</div>
                                    </div>
                                </ListGroup.Item>;
                            })}
                        </ListGroup>
                    </div>
                    <div className={styles.paginatorBody}>
                        <div className={"d-flex justify-content-center"}>
                            <Paginator currentPage={state.page}
                                totalPages={Math.ceil((state.totalItems ?? 0) / PAGE_SIZE)}
                                pageChangeCallback={newPage => runInAction(() => state.page = newPage)}/>
                        </div>
                    </div>
                </div>)}
            </div>
        </LoggedPage>);
    }}</Observer>;
}
