import React from "react";
import {GlobalState} from "../../../App";
import {API, DefaultModelData, ModelDefinition, RequestHandlingError} from "../../../api/API";
import {LoggedPage} from "../../loggedPage/LoggedPage";
import {observable, runInAction} from "mobx";
import {useLocalObservable, Observer} from "mobx-react";
import InputGroup from "react-bootstrap/cjs/InputGroup";
import FormControl from "react-bootstrap/cjs/FormControl";
import Button from "react-bootstrap/cjs/Button";
import {setSession} from "../../../utils/session";
import Spinner from "react-bootstrap/cjs/Spinner";

interface SettingsPageProps {
    globalState: GlobalState;
    models: ModelDefinition[];
    errorHandler: (error: Error) => void;
    api: API;
}

interface Setting {
    name: string,
    title: string,
    type: "text" | "number" | "password" | "email"
}

interface SettingCategory {
    name: string,
    settings: Setting[]
}

const settings: SettingCategory[] = [
    {
        name: "SMTP сервер",
        settings: [
            {
                name: "smtp-host",
                title: "Имя хоста",
                type: "text"
            },
            {
                name: "smtp-port",
                title: "Порт",
                type: "text"
            },
            {
                name: "smtp-username",
                title: "Имя пользователя",
                type: "text"
            },
            {
                name: "smtp-password",
                title: "Пароль",
                type: "password"
            },
            {
                name: "smtp-email",
                title: "EMail с которого идёт отправка",
                type: "email"
            }
        ]
    },
    {
        name: "Интеграция с AmoCRM",
        settings: [
            {
                name: "amocrm-domain",
                title: "Домен",
                type: "text"
            },
            {
                name: "amocrm-client-id",
                title: "ClientID приложения",
                type: "text"
            },
            {
                name: "amocrm-client-secret",
                title: "ClientSecret приложения",
                type: "password"
            },
            {
                name: "amocrm-redirect-url",
                title: "Страница редиректа",
                type: "text"
            },
            {
                name: "amocrm-token",
                title: "Токен",
                type: "password"
            }
        ]
    },
    {
        name: "Обработка заказов",
        settings: [
            {
                name: "order-email",
                title: "EMail для отправки заказов",
                type: "email"
            }
        ]
    }
];

interface SettingData {
    data: string;
}

type SettingsResponse = { count: number; items: (SettingData & DefaultModelData)[] };

export function SettingsPage(props: SettingsPageProps): JSX.Element {
    const state = useLocalObservable(() => ({
        currentSettings: observable.map<string, string>(),
        newSettings: observable.map<string, string>(),
        currentlyUpdating: observable.map<string, boolean>(),
        isLoading: false,
        isLoaded: false
    }));

    return <Observer>{() => {
        if (!state.isLoaded && !state.isLoading) {
            runInAction(() => {
                state.isLoading = true;
            });
            props.api.callApi("GET", "/api/admin/settings").then(data => {
                const response = data as SettingsResponse;
                for (const item of response.items) {
                    runInAction(() => {
                        state.currentSettings.set(item.id, item.data);
                        state.newSettings.set(item.id, item.data);
                    });
                }
                runInAction(() => {
                    state.isLoaded = true;
                    state.isLoading = false;
                });
            }).catch(e => {
                props.errorHandler(e);
                if (e instanceof RequestHandlingError) {
                    console.info(e.code);
                    if (e.code === 401) {
                        runInAction(() => {
                            props.globalState.session = setSession(null);
                        });
                    }
                }
                runInAction(() => {
                    state.isLoading = false;
                });
            });
        }

        return (<LoggedPage {...props}>
            <h1>Настройки</h1>
            {state.isLoading ? (
                <div className={"d-flex justify-content-center"}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div>) : (<div>
                {settings.map(category => (<div style={{marginTop: "30px", marginBottom: "10px"}} key={category.name}>
                    <h2>{category.name}</h2>
                    {category.settings.map(setting => (<div key={setting.name}>
                        <label>{setting.title}:</label>
                        <InputGroup className="mb-3">
                            <FormControl type={setting.type} value={state.newSettings.get(setting.name)}
                                onChange={e => state.newSettings.set(setting.name, e.target.value)}
                                disabled={state.currentlyUpdating.get(setting.name)} />
                            <InputGroup.Append>
                                <Button variant="warning"
                                    disabled={state.currentSettings.get(setting.name) === state.newSettings.get(setting.name)
                                || state.currentlyUpdating.get(setting.name)}
                                    onClick={() => {
                                        runInAction(() => {
                                            state.currentlyUpdating.set(setting.name, true);
                                        });
                                        props.api.callApi("PATCH", `/api/admin/settings/${setting.name}`, {
                                            data: state.newSettings.get(setting.name)
                                        }).then(() => {
                                            runInAction(() => {
                                                state.currentSettings.set(setting.name, state.newSettings.get(setting.name) ?? "");
                                                state.currentlyUpdating.set(setting.name, false);
                                            });
                                        }).catch(e => {
                                            props.errorHandler(e);
                                            runInAction(() => {
                                                state.currentlyUpdating.set(setting.name, false);
                                            });
                                        });
                                    }}>Сохранить</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>))}
                </div>))}</div>)}
        </LoggedPage>);
    }}</Observer>;
}
