import {CustomUpdatePageProps, UpdatableModel} from "../../../api/API";
import {useHistory} from "react-router-dom";
import {Observer, useLocalObservable} from "mobx-react";
import {LoggedPage} from "../../loggedPage/LoggedPage";
import Button from "react-bootstrap/cjs/Button";
import styles from "./ModelCreatePage.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import React from "react";
import Spinner from "react-bootstrap/cjs/Spinner";
import {getControl} from "./ModelCreatePage";
import {observable, runInAction} from "mobx";

export function ModelUpdatePage(props: CustomUpdatePageProps): JSX.Element {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        hasData: false,
        data: observable.map<string, unknown>({}),
        dataValid: observable.map<string, boolean>({}),
        dataPresented: observable.map<string, boolean>({}),
        isUpdating: false,
        isLoading: false,
        viewData() {
            const resultData: any = {};
            for (const property of props.model.properties) {
                if (!property.optional || (property.optional && this.dataPresented.get(property.name))) {
                    resultData[property.name] = this.hasData ? this.data.get(property.name) : undefined;
                }
            }
            return resultData;
        }
    }));

    return <Observer>{() => {
        for (const property of props.model.properties) {
            if (!state.dataValid.has(property.name)) {
                state.dataValid.set(property.name, false);
            }
        }

        if (!state.hasData && !state.isLoading) {
            runInAction(() => {
                state.isLoading = true;
            });
            props.model.endpoints.fetchSingle(props.id).then((response: any) => {
                const result: Record<string, unknown> = {};
                for (const key in response) {
                    if (!Object.prototype.hasOwnProperty.call(response, key))
                        continue;
                    result[key] = response[key];
                }
                for (const property of props.model.properties) {
                    state.dataPresented.set(property.name,
                        !!result[property.originalModelPropertyName ?? property.name]);
                }
                runInAction(() => {
                    state.hasData = true;
                    state.data = observable.map(result);
                    state.isLoading = false;
                });
            }).catch(e => {
                props.errorHandler(e);
                history.push(`/${props.model.name}`);
                runInAction(() => {
                    state.isLoading = false;
                });
            });
        }

        return (<LoggedPage {...props}>
            <div className={"clearfix"}>
                <Button className={`float-left ${styles.backButton}`}
                    onClick={() => history.push(`/${props.model.name}`)}>
                    <FontAwesomeIcon icon={faArrowLeft}/></Button>
                <h2 className={"float-left"}>Редактирование {props.model.crudCaseTitle}</h2>
            </div>
            <div className={styles.formBody}>
                {state.isLoading ? <div className={"d-flex justify-content-center"}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Загрузка...</span>
                    </Spinner>
                </div> :
                    <div>
                        <div>
                            {props.model.properties.map(property => getControl({
                                ...props,
                                property,
                                entityPropertyGetter: key => state.data.get(key),
                                valueChangeCallback: value => runInAction(() => {
                                    state.data.set(property.name, value);
                                }),
                                valueValidityCallback: valid => runInAction(() => state.dataValid.set(property.name, valid)),
                                propertyValue: state.hasData ? state.data.get(property.name) : undefined,
                                valuePresented: state.dataPresented.get(property.name) ?? false,
                                valueValid: state.dataValid.get(property.name) ?? false,
                                valuePresentedChangeCallback: presented => runInAction(() =>
                                    state.dataPresented.set(property.name, presented)),
                                showValid: true
                            }))}
                        </div>
                        <div className={"clearfix"}>
                            <Button variant={"warning"} className={"float-right"} disabled={state.isUpdating ||
                            Array.from(state.dataValid.keys()).filter(key => !state.dataPresented.has(key)
                                || state.dataPresented.get(key)).map(key => state.dataValid.get(key)).indexOf(false) !== -1}
                            onClick={() => {
                                runInAction(() => {
                                    state.isUpdating = true;
                                });
                                (props.model as UpdatableModel).endpoints.update(props.id, state.viewData()).then(() => {
                                    runInAction(() => {
                                        state.hasData = false;
                                        state.isUpdating = false;
                                    });
                                }).catch(e => {
                                    runInAction(() => {
                                        state.isUpdating = false;
                                    });
                                    props.errorHandler(e);
                                });
                            }}>Сохранить</Button>
                        </div>
                    </div>}
            </div>
        </LoggedPage>);
    }}</Observer>;
}
