import React from "react";
import Card from "react-bootstrap/cjs/Card";
import {ControlProps, getControl, VariantSelectProperty} from "../pages/model/ModelCreatePage";
import {useLocalObservable, Observer} from "mobx-react";
import {observable, runInAction} from "mobx";
import {ModelPropertyDefinition} from "../../api/API";
import {MAIN_CURRENCY_SIGN} from "../../index";

export enum PropertyType {
    string = "string",
    number = "number",
    stringArray = "stringArray",
    stringMap = "stringMap",
    numberMap = "numberMap",
    boolean = "boolean"
}

export interface AbstractProperty {
    type: PropertyType
}

export interface StringProperty extends AbstractProperty {
    type: PropertyType.string,
    value: string
}

export interface NumberProperty extends AbstractProperty {
    type: PropertyType.number,
    value: number
}

export interface StringArrayProperty extends AbstractProperty {
    type: PropertyType.stringArray,
    value: string[]
}

export interface StringMapProperty extends AbstractProperty {
    type: PropertyType.stringMap,
    value: Record<string, string>
}

export interface NumberMapProperty extends AbstractProperty {
    type: PropertyType.numberMap,
    value: Record<string, number>
}

export interface BooleanProperty extends AbstractProperty {
    type: PropertyType.boolean,
    value: boolean
}

export type Property =
    StringProperty
    | NumberProperty
    | StringArrayProperty
    | StringMapProperty
    | NumberMapProperty
    | BooleanProperty;

type CardType = "grassMix" | "rolledLawn" | "fertilizer" | "woods" | "soil" | "antiIce" | "other";

const cardTypes: Record<CardType, string> = {
    "grassMix": "Травосмесь",
    "rolledLawn": "Рулонный газон",
    "fertilizer": "Удобрение",
    "woods": "Растение",
    "antiIce": "Антигололёдный реагент",
    "soil": "Грунт",
    "other": "Другое"
};

function getResultFromDataAndProperties(cardType: CardType, rawData: Record<string, unknown>): Record<string, Property> {
    const result: Record<string, Property> = {};

    result["mainImageUrl"] = {
        type: PropertyType.string,
        value: rawData["mainImageUrl"] as string
    };
    result["imageUrls"] = {
        type: PropertyType.stringArray,
        value: rawData["imageUrls"] as string[]
    };
    if (cardType === "grassMix" || cardType === "rolledLawn") {
        result["purpose"] = {
            type: PropertyType.string,
            value: rawData["purpose"] as string
        };

        result["contents"] = {
            type: PropertyType.numberMap,
            value: rawData["contents"] as Record<string, number>
        };
    }

    if (cardType !== "woods") {
        result["unit"] = {
            type: PropertyType.string,
            value: rawData["unit"] as string
        };
    }

    if (cardType !== "woods" && cardType !== "soil") {
        result["wrapping"] = {
            type: PropertyType.string,
            value: rawData["wrapping"] as string
        };

        result["pack"] = {
            type: PropertyType.number,
            value: rawData["pack"] as number
        };

        if (cardType !== "antiIce" && cardType !== "other") {
            result["packPrice"] = {
                type: PropertyType.number,
                value: rawData["packPrice"] as number
            };
        }
    }

    if (cardType === "grassMix" || cardType === "rolledLawn" || cardType === "antiIce" || cardType === "other") {
        result["manufacturer"] = {
            type: PropertyType.string,
            value: rawData["manufacturer"] as string
        };
    }

    if (cardType === "grassMix" || cardType === "rolledLawn" || cardType === "antiIce") {
        for (let i = 1; i <= 3; i++) {
            if (rawData[`priceAmountStart${i}`] !== undefined) {
                result[`priceAmountStart${i}`] = {
                    type: PropertyType.number,
                    value: rawData[`priceAmountStart${i}`] as number
                };
                result[`price${i}`] = {
                    type: PropertyType.number,
                    value: rawData[`price${i}`] as number
                };
            }
        }
    }

    if (cardType === "rolledLawn" || cardType === "fertilizer" || cardType === "antiIce") {
        result["spending"] = {
            type: PropertyType.number,
            value: rawData["spending"] as number
        };
    }

    result["isHit"] = {
        type: PropertyType.boolean,
        value: rawData["isHit"] as boolean
    };
    result["additionalInfo"] = {
        type: PropertyType.string,
        value: rawData["additionalInfo"] as string
    };

    result["cardType"] = {
        type: PropertyType.string,
        value: cardType
    };
    return result;
}

const purposeVariants = [
    "Благоустройство и озеленение",
    "Строительные и дорожные работы",
    "Ландшафтный дизайн",
    "Для обширных работ",
    "Для дачи",
    "Для города и парковых зон"
];

function getPropertyDefinitionsForType(cardType: CardType, dataGetter: (key: string) => unknown): ModelPropertyDefinition[] {
    const propertyDefinitions: ModelPropertyDefinition[] = [];
    propertyDefinitions.push({
        type: "image",
        name: "mainImageUrl",
        title: "Основное изображение"
    });
    propertyDefinitions.push({
        type: "imageArray",
        name: "imageUrls",
        title: "Дополнительные изображения"
    });

    if (cardType === "grassMix" || cardType === "rolledLawn") {
        propertyDefinitions.push({
            type: "variantSelect",
            variants: purposeVariants,
            mapper: key => key,
            name: "purpose",
            title: "Назначение"
        });

        propertyDefinitions.push({
            type: "numberMap",
            name: "contents",
            title: "Состав",
            valueUnit: "%"
        });
    }

    if (cardType !== "woods") {
        propertyDefinitions.push({
            type: "string",
            name: "unit",
            title: "Единица измерения"
        });
    }

    if (cardType !== "woods" && cardType !== "soil") {
        propertyDefinitions.push({
            type: "string",
            name: "wrapping",
            title: "Упаковка"
        });

        propertyDefinitions.push({
            type: "number",
            name: "pack",
            title: "Размер упаковки",
            unit: dataGetter("unit") as undefined | string,
            min: 0
        });

        if (cardType !== "antiIce" && cardType !== "other") {
            propertyDefinitions.push({
                type: "number",
                name: "packPrice",
                title: "Цена упаковки (0 для \"по запросу\")",
                min: 0,
                unit: MAIN_CURRENCY_SIGN
            });
        }
    }

    if (cardType === "grassMix" || cardType === "rolledLawn" || cardType === "antiIce" || cardType === "other") {
        propertyDefinitions.push({
            type: "string",
            name: "manufacturer",
            title: "Производитель"
        });
    }

    if (cardType === "grassMix" || cardType === "rolledLawn" || cardType === "antiIce") {
        let maxPriceNumber = 0;
        while (maxPriceNumber < 3) {
            if (dataGetter(`priceAmountStart${maxPriceNumber + 1}`) !== undefined) {
                maxPriceNumber++;
                continue;
            }
            break;
        }
        for (let i = 1; i <= Math.min(3, maxPriceNumber + 1); i++) {
            propertyDefinitions.push({
                type: "number",
                name: `priceAmountStart${i}`,
                title: `Количество №${i}`,
                optional: i - 1 == maxPriceNumber || i == maxPriceNumber,
                min: i === 1 ? 0 : (dataGetter(`priceAmountStart${i - 1}`) ?? 0) as number,
                unit: dataGetter("unit") as (string | undefined)
            });
            if (dataGetter(`priceAmountStart${i}`) !== undefined) {
                propertyDefinitions.push({
                    type: "number",
                    name: `price${i}`,
                    title: `Цена за количество > ${dataGetter(`priceAmountStart${i}`) ?? 0} ${dataGetter("unit")} (0 для "по запросу")`,
                    unit: MAIN_CURRENCY_SIGN,
                    min: 0
                });
            }
        }
    }

    if (cardType === "rolledLawn" || cardType === "fertilizer" || cardType === "antiIce") {
        propertyDefinitions.push({
            type: "number",
            name: "spending",
            title: "Расход",
            unit: dataGetter("unit") as (string | undefined),
            min: 0
        });
    }

    propertyDefinitions.push({
        type: "boolean",
        name: "isHit",
        title: "Хит"
    });
    propertyDefinitions.push({
        type: "visualEditor",
        name: "additionalInfo",
        title: "Дополнительная информация"
    });
    return propertyDefinitions;
}

export function ProductPropertiesProperty(props: ControlProps): JSX.Element {
    const properties = props.propertyValue as Record<string, Property> ?? {};

    const state = useLocalObservable(() => ({
        cardType: ((properties["cardType"] as StringProperty)?.value ?? "other") as CardType,
        properties: observable.map<string, unknown>({}),
        propertiesPresented: observable.map<string, boolean>({}),
        propertiesValid: observable.map<string, boolean>({}),
        getPropertyCalculated(key: string): unknown {
            return (!this.propertiesPresented.has(key) || this.propertiesPresented.get(key) === true) ?
                this.properties.get(key) : undefined;
        },
        isValid(): boolean {
            const propertyDefinitions = getPropertyDefinitionsForType(this.cardType, key => this.getPropertyCalculated(key));

            let isValid = true;
            for (const property of propertyDefinitions) {
                if (!property.optional && !this.propertiesValid.get(property.name)) {
                    isValid = false;
                }
                if (property.optional && this.propertiesPresented.get(property.name) &&
                    !this.propertiesValid.get(property.name)) {
                    isValid = false;
                }
            }

            return isValid;
        },
        result(): Record<string, Property> {
            const propertyDefinitions = getPropertyDefinitionsForType(this.cardType, key => this.getPropertyCalculated(key));

            const rawData: Record<string, unknown> = {};
            for (const property of propertyDefinitions) {
                if (!property.optional || (property.optional && this.propertiesPresented.get(property.name))) {
                    rawData[property.name] = this.properties.get(property.name);
                }
            }

            return getResultFromDataAndProperties(this.cardType, rawData);
        }
    }));

    return <Observer>{() => {
        const propertyDefinitions = getPropertyDefinitionsForType(state.cardType, key => state.getPropertyCalculated(key));

        runInAction(() => {
            for (const property of propertyDefinitions) {
                if (!state.properties.has(property.name)) {
                    state.properties.set(property.name, properties[property.name]?.value);
                }
                if (!state.propertiesValid.has(property.name)) {
                    state.propertiesValid.set(property.name, false);
                }
                if (!state.propertiesPresented.has(property.name)) {
                    state.propertiesPresented.set(property.name, !property.optional || !!properties[property.name]);
                }
            }
        });

        return (<div style={{marginBottom: "20px"}}>
            <VariantSelectProperty variants={Object.keys(cardTypes) as CardType[]}
                mapper={type => cardTypes[type] ?? type}
                value={state.cardType}
                valuePresented={true}
                valueChangeCallback={value => runInAction(() => {
                    if (state.cardType != value) {
                        state.cardType = value;
                        props.valueChangeCallback(state.result());
                        props.valueValidityCallback(state.isValid());
                    }
                })}
                valuePresentedChangeCallback={() => {
                    /* ignored */
                }}
                valueValidityCallback={() => {
                    /* ignored */
                }}
                valueValid={true}
                title={"Тип товара"}
                name={"cardType"}
                readonly={false}
                optional={false}
                showValid={true}
                errorHandler={props.errorHandler}/>
            <Card border={props.valueValid ? "success" : "danger"}>
                <Card.Body>
                    {propertyDefinitions.map(property => {
                        return getControl({
                            api: props.api,
                            entityPropertyGetter: () => { throw new Error(); },
                            propertyValue: state.properties.get(property.name),
                            valuePresented: state.propertiesPresented.get(property.name) ?? false,
                            valueValid: state.propertiesValid.get(property.name) ?? false,
                            valueChangeCallback: value => runInAction(() => {
                                if (state.properties.get(property.name) !== value) {
                                    state.properties.set(property.name, value);
                                    props.valueChangeCallback(state.result());
                                }
                            }),
                            valuePresentedChangeCallback: presented => runInAction(() => {
                                if (state.propertiesPresented.get(property.name) !== presented) {
                                    state.propertiesPresented.set(property.name, presented);
                                    props.valueChangeCallback(state.result());
                                    props.valueValidityCallback(state.isValid());
                                }
                            }),
                            valueValidityCallback: valid => runInAction(() => {
                                if (state.propertiesValid.get(property.name) !== valid) {
                                    state.propertiesValid.set(property.name, valid);
                                    props.valueValidityCallback(state.isValid());
                                }
                            }),
                            property,
                            model: props.model,
                            models: props.models,
                            errorHandler: props.errorHandler,
                            showValid: true
                        });
                    })}
                </Card.Body>
            </Card>
        </div>);
    }}</Observer>;
}
