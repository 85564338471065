import {
    API, CreatableModel,
    CustomCreatePageProps, DefaultModelData, ModelDefinition,
    ModelPropertyDefinition
} from "../../../api/API";
import {useHistory} from "react-router-dom";
import {Observer, useLocalObservable} from "mobx-react";
import {LoggedPage} from "../../loggedPage/LoggedPage";
import Button from "react-bootstrap/cjs/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import styles from "./ModelCreatePage.module.css";
import React, {useEffect, useRef} from "react";
import {FormControl, InputGroup} from "react-bootstrap/cjs";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import {observable, runInAction} from "mobx";
import Card from "react-bootstrap/cjs/Card";
import FormFile from "react-bootstrap/cjs/FormFile";
import Image from "react-bootstrap/cjs/Image";
import Spinner from "react-bootstrap/cjs/Spinner";
import ListGroup from "react-bootstrap/cjs/ListGroup";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {DateTimeProperty} from "../../fields/DateTimeProperty";
import Col from "react-bootstrap/cjs/Col";
import Row from "react-bootstrap/cjs/Row";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import StringProperty from "../../fields/StringProperty";

export interface ControlProps {
    api: API;
    entityPropertyGetter: (key: string) => unknown;
    propertyValue: unknown;
    showValid: boolean;
    valuePresented: boolean;
    valueValid: boolean;
    valueChangeCallback: (value: unknown) => void;
    valueValidityCallback: (valid: boolean) => void;
    valuePresentedChangeCallback: (value: boolean) => void;
    property: ModelPropertyDefinition;
    model: ModelDefinition;
    models: ModelDefinition[];
    errorHandler: (error: Error) => void;
}

export interface CommonPropertyProps<T> {
    value: T;
    valuePresented: boolean;
    valueValid: boolean;
    valueChangeCallback: (value: T) => void;
    valuePresentedChangeCallback: (value: boolean) => void;
    valueValidityCallback: (value: boolean) => void;
    title: string;
    name: string;
    readonly: boolean;
    optional: boolean;
    errorHandler: (error: Error) => void;
    showValid: boolean;
}

function isTime(value: string): boolean {
    return /([0-9]{1,2}):([0-9]{2})/.test(value);
}

export function TimeProperty(props: CommonPropertyProps<string>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(isTime(props.value));
        props.valueChangeCallback(props.value);
    });

    return <div>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> :
            <InputGroup className="mb-3">
                <FormControl id={props.name} type={"time"} value={props.value} disabled={props.readonly}
                    isInvalid={!props.valueValid} isValid={props.showValid && props.valueValid}
                    onChange={(e) => {
                        if (props.readonly) {
                            return false;
                        } else {
                            props.valueValidityCallback(isTime(e.target.value));
                            return props.valueChangeCallback(e.target.value);
                        }
                    }}/>
            </InputGroup>
        }
    </div>;
}

export function RichStringProperty(props: CommonPropertyProps<string>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    return <div>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> :
            <InputGroup className="mb-3">
                <FormControl as="textarea" id={props.name} value={props.value} disabled={props.readonly}
                    isInvalid={!props.valueValid} isValid={props.showValid && props.valueValid}
                    onChange={(e) => props.readonly ? false : props.valueChangeCallback(e.target.value)}/>
            </InputGroup>
        }
    </div>;
}

function clamp(value: number, min?: number, max?: number): number {
    if (min !== undefined) {
        value = Math.max(value, min);
    }
    if (max !== undefined) {
        value = Math.min(value, max);
    }
    return value;
}

export function NumberProperty(props: {
    unit?: string,
    min?: number,
    max?: number
} & CommonPropertyProps<number>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback((props.min === undefined || props.value >= props.min) &&
            (props.max === undefined || props.value <= props.max));
        props.valueChangeCallback(props.value);
    });

    return <div>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> :
            <InputGroup className="mb-3">
                <FormControl type={"number"} id={props.name} value={props.value.toString()}
                    disabled={props.readonly}
                    isInvalid={!props.valueValid} isValid={props.showValid && props.valueValid}
                    onBlur={() => props.readonly ? false : props.valueChangeCallback(
                        clamp(Number(props.value), props.min, props.max))}
                    onChange={(e) => props.readonly ? false : props.valueChangeCallback(Number(e.target.value))}/>
                {props.unit ? <InputGroup.Append>
                    <InputGroup.Text>{props.unit}</InputGroup.Text>
                </InputGroup.Append> : <></>}
            </InputGroup>
        }
    </div>;
}

export function VariantSelectProperty<T extends string = string>(props: {
    variants: T[],
    mapper: (key: T) => string | undefined
} & CommonPropertyProps<T>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    return <div>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> :
            <InputGroup className="mb-3">
                <FormControl as={"select"} id={props.name} value={String(props.value)} disabled={props.readonly}
                    isInvalid={!props.valueValid} isValid={props.showValid && props.valueValid}
                    onChange={(e) => props.readonly ? false : props.valueChangeCallback(e.target.value as T)}>
                    {props.variants.map(variant => (
                        <option value={variant} key={variant}>{props.mapper(variant)}</option>))}
                </FormControl>
            </InputGroup>
        }
    </div>;
}

export function OtherSelectorProperty(props: {
    sourceValue: DefaultModelData,
    otherModel: ModelDefinition,
    otherModelMapper: (data: any) => string,
    filter: (entityPropertyGetter: (key: string) => any, variant: any) => boolean,
    entityPropertyGetter: (key: string) => any
} & CommonPropertyProps<string>): JSX.Element {
    const state = useLocalObservable(() => ({
        isLoading: false,
        items: null as ((unknown & DefaultModelData)[] | null),
        initialValueSet: false
    }));

    useEffect(() => {
        props.valueValidityCallback(false);
    }, []);

    return <Observer>{() => {
        if (!state.isLoading && state.items === null) {
            state.isLoading = true;
            (async () => {
                const totalCount = (await props.otherModel.endpoints.fetchMany(0, 0)).count;
                const result: (unknown & DefaultModelData)[] = [];
                for (let page = 0; page < Math.ceil(totalCount / API.MAX_TAKE_SIZE); page++) {
                    result.push(...(await props.otherModel.endpoints
                        .fetchMany(page * API.MAX_TAKE_SIZE, API.MAX_TAKE_SIZE)).items);
                }
                return result;
            })().then(result => {
                runInAction(() => {
                    state.items = result;
                    const currentItems = state.items.filter(item => props.filter(props.entityPropertyGetter, item));
                    if (currentItems.length > 0) {
                        if (currentItems.find(item => item.id === props.sourceValue?.id)) {
                            props.valueChangeCallback(props.sourceValue?.id ?? currentItems[0].id);
                        } else {
                            props.valueChangeCallback(currentItems[0].id);
                        }
                    } else {
                        props.valueChangeCallback(props.sourceValue?.id ?? "");
                    }
                    state.isLoading = false;
                    props.valueValidityCallback(state.items.filter(item =>
                        props.filter(props.entityPropertyGetter, item)).length > 0 && !state.isLoading);
                });
            }).catch(e => {
                props.errorHandler(e);
                runInAction(() => {
                    props.valueChangeCallback(props.sourceValue?.id ?? "");
                    state.items = [];
                    state.isLoading = false;
                });
            });
        }
        return (<div>
            {props.optional ?
                <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented}
                    onChange={(e) =>
                        props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
            <label htmlFor={props.name}>{props.title}:</label>
            {props.optional && !props.valuePresented ? <></> :
                <InputGroup className="mb-3">
                    {state.items ?
                        <FormControl as={"select"} id={props.name} value={props.value} disabled={props.readonly}
                            isInvalid={!props.valueValid} isValid={props.showValid && props.valueValid}
                            onChange={(e) => {
                                props.valueValidityCallback(state.items !== null && state.items.filter(item =>
                                    props.filter(props.entityPropertyGetter, item)).length > 0 && !state.isLoading);
                                return props.readonly ? false : props.valueChangeCallback(e.target.value);
                            }}>
                            {state.items.filter(item => props.filter(props.entityPropertyGetter, item)).map(item => (
                                <option value={item.id} key={item.id}>{props.otherModelMapper(item)}</option>))}
                        </FormControl>
                        : <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </Spinner>
                    }
                </InputGroup>
            }
        </div>);
    }}</Observer>;
}

export function BooleanProperty(props: CommonPropertyProps<boolean>): JSX.Element {
    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    if (props.optional) {
        throw new Error("Optional boolean");
    }

    return <div>
        <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.value} disabled={props.readonly}
            onChange={(e) =>
                props.readonly ? false : props.valueChangeCallback(e.target.checked)} id={props.name}
            style={({marginRight: "10px"})}/>
        <label htmlFor={props.name}>{props.title}</label>
    </div>;
}

const isUrl = /http(s?):\/\//;

interface AssetUploadResponse {
    url: string;
}

export function ImageProperty(props: {
    api: API
} & CommonPropertyProps<string>): JSX.Element {
    const state = useLocalObservable(() => ({
        isUploading: false,
        selectedFile: null as (string | Blob | null)
    }));

    const formFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        props.valueValidityCallback(isUrl.test(props.value));
        props.valueChangeCallback(props.value);
    });

    function formFileHandler(e: any): void {
        state.selectedFile = e.target.files[0] ?? null;
    }

    return <Observer>{() => (<div style={{marginBottom: "20px"}}>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <span>{props.title}:</span>
        {props.optional && !props.valuePresented ? <></> :
            <Card style={{marginTop: "10px"}} border={props.valueValid ? "success" : "danger"}>
                <Card.Body>
                    <div style={{marginBottom: "10px"}}>
                        <span>Текущее изображение:</span>
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        {state.isUploading ? <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </Spinner> : isUrl.test(props.value) ? <Image src={props.value} height={200}
                            thumbnail/> : <>Нет</>}
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        <span>Загрузить новое:</span>
                    </div>
                    <div className={"clearfix"}>
                        <FormFile className={"float-left"} onChange={formFileHandler} ref={formFile}/>
                        <Button className={"float-right"} disabled={state.selectedFile === null || state.isUploading}
                            onClick={() => {
                                if (!state.selectedFile) {
                                    return;
                                }

                                state.isUploading = true;
                                props.valueValidityCallback(false);
                                const formData = new FormData();

                                formData.append("asset", state.selectedFile);

                                props.api.callApi("POST", "/api/admin/assets", formData).then(response => {
                                    state.isUploading = false;
                                    props.valueChangeCallback((response as AssetUploadResponse).url);
                                    props.valueValidityCallback(true);
                                    state.selectedFile = null;
                                    if (formFile.current) {
                                        formFile.current.value = "";
                                    }
                                }).catch(error => {
                                    props.errorHandler(error);
                                    state.isUploading = false;
                                    props.valueValidityCallback(isUrl.test(props.value));
                                });
                            }}>{state.isUploading ? "Загрузка..." : "Загрузить"}</Button>
                    </div>
                </Card.Body>
            </Card>
        }
    </div>)}</Observer>;
}

export function FileProperty(props: {
    api: API
} & CommonPropertyProps<string>): JSX.Element {
    const state = useLocalObservable(() => ({
        isUploading: false,
        selectedFile: null as (string | Blob | null)
    }));

    const formFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        props.valueValidityCallback(isUrl.test(props.value));
        props.valueChangeCallback(props.value);
    });

    function formFileHandler(e: any): void {
        state.selectedFile = e.target.files[0] ?? null;
    }

    return <Observer>{() => (<div style={{marginBottom: "20px"}}>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <span>{props.title}:</span>
        {props.optional && !props.valuePresented ? <></> :
            <Card style={{marginTop: "10px"}} border={props.valueValid ? "success" : "danger"}>
                <Card.Body>
                    <div style={{marginBottom: "10px"}}>
                        <span>Текущий файл: </span>
                        {state.isUploading ? <Spinner animation="border" role="status">
                            <span className="sr-only">Загрузка...</span>
                        </Spinner> : isUrl.test(props.value) ?
                            <a href={props.value} rel={"noreferrer"} target={"_blank"}>Файл</a> : <>Нет</>}
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        <span>Загрузить новый:</span>
                    </div>
                    <div className={"clearfix"}>
                        <FormFile className={"float-left"} onChange={formFileHandler} ref={formFile}/>
                        <Button className={"float-right"} disabled={state.selectedFile === null || state.isUploading}
                            onClick={() => {
                                if (!state.selectedFile) {
                                    return;
                                }

                                state.isUploading = true;
                                props.valueValidityCallback(false);
                                const formData = new FormData();

                                formData.append("asset", state.selectedFile);

                                props.api.callApi("POST", "/api/admin/assets", formData).then(response => {
                                    state.isUploading = false;
                                    props.valueChangeCallback((response as AssetUploadResponse).url);
                                    props.valueValidityCallback(true);
                                    state.selectedFile = null;
                                    if (formFile.current) {
                                        formFile.current.value = "";
                                    }
                                }).catch(error => {
                                    props.errorHandler(error);
                                    state.isUploading = false;
                                    props.valueValidityCallback(isUrl.test(props.value));
                                });
                            }}>{state.isUploading ? "Загрузка..." : "Загрузить"}</Button>
                    </div>
                </Card.Body>
            </Card>
        }
    </div>)}</Observer>;
}

export function ImageArrayProperty(props: {
    api: API
} & CommonPropertyProps<string[]>): JSX.Element {
    const state = useLocalObservable(() => ({
        isUploading: false,
        selectedFile: null as (string | Blob | null)
    }));

    const formFile = useRef<HTMLInputElement>(null);

    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    function formFileHandler(e: any): void {
        state.selectedFile = e.target.files[0] ?? null;
    }

    return <Observer>{() => (<div style={{marginBottom: "20px"}}>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <span>{props.title}:</span>
        {props.optional && !props.valuePresented ? <></> :
            <Card style={{marginTop: "10px"}} border={props.valueValid ? "success" : "danger"}>
                <Card.Body>
                    <div style={{marginBottom: "10px"}}>
                        <span>Текущие изображения:</span>
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        {props.value.length === 0 && !state.isUploading ? <>Нет</> : <></>}
                        <ListGroup>
                            {props.value.map((url, index) => (<ListGroup.Item key={index}>
                                <div className={"clearfix"}>
                                    <Image className={"float-left"} src={url} height={200} thumbnail/>
                                    <Button className={"float-right"} variant={"danger"} onClick={() => {
                                        const values = props.value.slice();
                                        values.splice(index, 1);
                                        props.valueChangeCallback(values);
                                    }}><FontAwesomeIcon
                                            icon={faTrash}/></Button>
                                </div>
                            </ListGroup.Item>))}
                            {state.isUploading ?
                                <ListGroup.Item>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Загрузка...</span>
                                    </Spinner>
                                </ListGroup.Item> : <></>
                            }
                        </ListGroup>
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        <span>Загрузить новое:</span>
                    </div>
                    <div className={"clearfix"}>
                        <FormFile className={"float-left"} onChange={formFileHandler} ref={formFile}/>
                        <Button className={"float-right"} disabled={state.selectedFile === null || state.isUploading}
                            onClick={() => {
                                if (!state.selectedFile) {
                                    return;
                                }

                                state.isUploading = true;
                                props.valueValidityCallback(false);
                                const formData = new FormData();

                                formData.append("asset", state.selectedFile);

                                props.api.callApi("POST", "/api/admin/assets", formData).then(response => {
                                    state.isUploading = false;
                                    const images = props.value.slice();
                                    images.push((response as AssetUploadResponse).url);
                                    props.valueChangeCallback(images);
                                    state.selectedFile = null;
                                    if (formFile.current) {
                                        formFile.current.value = "";
                                    }
                                }).catch(error => {
                                    props.errorHandler(error);
                                    state.isUploading = false;
                                });
                            }}>{state.isUploading ? "Загрузка..." : "Загрузить"}</Button>
                    </div>
                </Card.Body>
            </Card>
        }
    </div>)}</Observer>;
}

export function NumberMapProperty(props: {
    keyUnit?: string,
    valueUnit?: string
} & CommonPropertyProps<Record<string, number>>): JSX.Element {
    const state = useLocalObservable(() => ({
        currentKey: "",
        currentValue: 0
    }));

    useEffect(() => {
        props.valueValidityCallback(true);
        props.valueChangeCallback(props.value);
    });

    return <Observer>{() => (<div style={{marginBottom: "20px"}}>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <span>{props.title}:</span>
        {props.optional && !props.valuePresented ? <></> :
            <Card style={{marginTop: "10px"}} border={props.valueValid ? "success" : "danger"}>
                <Card.Body>
                    <div style={{marginBottom: "10px"}}>
                        {Object.keys(props.value).length === 0 ? <>Нет</> : <></>}
                        <ListGroup>
                            {Object.keys(props.value).map((key) => (<ListGroup.Item key={key}>

                                <Row>
                                    <Col xs={5}>
                                        <InputGroup className="mb-3">
                                            <FormControl value={key} disabled={true} />
                                            {
                                                props.keyUnit ? (<InputGroup.Append>
                                                    <InputGroup.Text>{props.keyUnit}</InputGroup.Text>
                                                </InputGroup.Append>) : <></>
                                            }
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <InputGroup className="mb-3">
                                            <FormControl type={"number"} value={props.value[key]} onChange={e => {
                                                const oldValue = props.value;
                                                oldValue[key] = Number(e.target.value);
                                                props.valueChangeCallback(oldValue);
                                            }}/>
                                            {
                                                props.valueUnit ? (<InputGroup.Append>
                                                    <InputGroup.Text>{props.valueUnit}</InputGroup.Text>
                                                </InputGroup.Append>) : <></>
                                            }
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant={"danger"} onClick={() => {
                                            const oldValue = props.value;
                                            delete oldValue[key];
                                            props.valueChangeCallback(oldValue);
                                        }} style={{width: "100%"}}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </Col>
                                </Row>
                                <div className={"clearfix"}>
                                </div>
                            </ListGroup.Item>))}
                        </ListGroup>
                    </div>
                    <div style={{marginBottom: "10px"}}/>
                    <div style={{marginBottom: "10px", borderTop: "1px color gray", width: "100%"}}/>
                    <div>
                        <Row>
                            <Col xs={5}>
                                <InputGroup className="mb-3">
                                    <FormControl value={state.currentKey} isInvalid={props.value[state.currentKey] !== undefined}
                                        isValid={props.showValid && props.value[state.currentKey] === undefined}
                                        onChange={e => runInAction(() => state.currentKey = e.target.value)}/>
                                    {
                                        props.keyUnit ? (<InputGroup.Append>
                                            <InputGroup.Text>{props.keyUnit}</InputGroup.Text>
                                        </InputGroup.Append>) : <></>
                                    }
                                </InputGroup>
                            </Col>
                            <Col xs={5}>
                                <InputGroup className="mb-3">
                                    <FormControl value={state.currentValue}
                                        type={"number"} isValid={props.showValid}
                                        onChange={e => runInAction(() => state.currentValue = Number(e.target.value))}/>
                                    {
                                        props.valueUnit ? (<InputGroup.Append>
                                            <InputGroup.Text>{props.valueUnit}</InputGroup.Text>
                                        </InputGroup.Append>) : <></>
                                    }
                                </InputGroup>
                            </Col>
                            <Col xs={2}>
                                <Button variant={"primary"} disabled={props.value[state.currentKey] !== undefined} onClick={() => {
                                    const oldValue = props.value;
                                    oldValue[state.currentKey] = state.currentValue;
                                    runInAction(() => {
                                        state.currentKey = "";
                                        state.currentValue = 0;
                                    });
                                    props.valueChangeCallback(oldValue);
                                }} style={{width: "100%"}}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        }
    </div>)}</Observer>;
}

export function VisualEditorProperty(props: {
    api: API
} & CommonPropertyProps<string>): JSX.Element {
    useEffect(() => {
        if (!props.readonly) {
            props.valueChangeCallback(props.value);
        }
        props.valueValidityCallback(true);
    });

    return <div style={{marginBottom: "20px"}}>
        {props.optional ?
            <input type={"checkbox"} className={styles.optionalCheckBox} checked={props.valuePresented} onChange={(e) =>
                props.valuePresentedChangeCallback(e.target.checked)}/> : <></>}
        <label htmlFor={props.name}>{props.title}:</label>
        {props.optional && !props.valuePresented ? <></> : <>
            {props.readonly ?
                <InputGroup className="mb-3">
                    <FormControl as="textarea" id={props.name} value={props.value} disabled/>
                </InputGroup> :
                <Editor
                    value={props.value}
                    id={props.name}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount"
                        ],
                        toolbar:
                            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image | code",
                        skin: false,
                        content_css: false,
                        automatic_uploads: true,
                        image_title: true,
                        images_upload_handler: (blobInfo, success, failure) => {
                            const formData = new FormData();
                            formData.append("asset", blobInfo.blob(), blobInfo.filename());
                            props.api.callApi("POST", "/api/admin/assets", formData).then(response => {
                                const uploadResponse = (response as AssetUploadResponse);
                                success(uploadResponse.url);
                            }).catch(e => failure(String(e)));
                        }
                    }}
                    onEditorChange={(e) => props.valueChangeCallback(e)}
                />}</>
        }
    </div>;
}

export function getControl(props: ControlProps): JSX.Element {
    const property = props.property;
    const commonProps = {
        valueChangeCallback: props.valueChangeCallback,
        valueValidityCallback: props.valueValidityCallback,
        valuePresentedChangeCallback: props.valuePresentedChangeCallback,
        valuePresented: props.valuePresented,
        valueValid: props.valueValid,
        title: property.title,
        name: property.name,
        readonly: property.readonly ?? false,
        key: property.name,
        optional: property.optional ?? false,
        errorHandler: props.errorHandler,
        showValid: props.showValid
    };
    switch (property.type) {
    case "numberMap":
        return <NumberMapProperty value={props.propertyValue ? (props.propertyValue as Record<string, number>) : {}}
            {...commonProps} keyUnit={property.keyUnit} valueUnit={property.valueUnit}/>;
    case "custom":
        return React.createElement(property.customProperty, {
            ...props,
            key: property.name
        });
    case "time":
        return <TimeProperty value={props.propertyValue ? String(props.propertyValue) : ""} {...commonProps}/>;
    case "imageArray":
        return <ImageArrayProperty value={props.propertyValue ? (props.propertyValue as string[]) : []}
            api={props.api} {...commonProps}/>;
    case "visualEditor":
        return <VisualEditorProperty api={props.api} value={props.propertyValue ? String(props.propertyValue) : ""}
            {...commonProps}/>;
    case "otherSelector": {
        const otherModel = props.models
            .find(model => model.name === property.otherModelName);
        if (!otherModel) {
            throw new Error(`Other model ${property.otherModelName} not found`);
        }
        const sourceValue = props.entityPropertyGetter(property.originalModelPropertyName) as DefaultModelData;
        return <OtherSelectorProperty value={props.propertyValue ? String(props.propertyValue) : ""}
            otherModel={otherModel}
            otherModelMapper={property.otherModelMapper}
            sourceValue={sourceValue}
            filter={property.filter}
            entityPropertyGetter={props.entityPropertyGetter}
            {...commonProps}/>;
    }
    case "image":
        return <ImageProperty value={props.propertyValue ? String(props.propertyValue) : ""}
            api={props.api}
            {...commonProps}/>;
    case "file":
        return <FileProperty value={props.propertyValue ? String(props.propertyValue) : ""}
            api={props.api}
            {...commonProps}/>;
    case "boolean":
        return <BooleanProperty value={props.propertyValue ? Boolean(props.propertyValue) : false}
            {...commonProps}/>;
    case "datetime":
        return <DateTimeProperty value={props.propertyValue ? new Date(String(props.propertyValue)) : new Date()}
            {...commonProps}/>;
    case "string":
        return <StringProperty value={props.propertyValue ? String(props.propertyValue) : ""}
            unit={property.unit} maxLength={property.maxLength}
            {...commonProps}/>;
    case "number":
        return <NumberProperty value={props.propertyValue ? Number(props.propertyValue) : 0}
            unit={property.unit} max={property.max} min={property.min}
            {...commonProps}/>;
    case "richString":
        return <RichStringProperty value={props.propertyValue ? String(props.propertyValue) : ""}
            {...commonProps}/>;
    case "variantSelect":
        return <VariantSelectProperty
            value={props.propertyValue ? String(props.propertyValue) : property.variants[0]}
            variants={property.variants}
            mapper={property.mapper}
            {...commonProps}/>;
    default:
        throw new Error("wrong type");
    }
}

export function ModelCreatePage(props: CustomCreatePageProps): JSX.Element {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        data: observable.map<string, unknown>({}),
        dataValid: observable.map<string, boolean>({}),
        dataPresented: observable.map<string, boolean>({}),
        isCreating: false,
        viewData() {
            const resultData: any = {};
            for (const property of props.model.properties) {
                if (!property.optional || (property.optional && this.dataPresented.get(property.name))) {
                    resultData[property.name] = this.data.get(property.name);
                }
            }
            return resultData;
        }
    }));

    return <Observer>{() => {
        for (const property of props.model.properties) {
            if (!state.dataValid.has(property.name)) {
                state.dataValid.set(property.name, false);
            }
            if (!state.dataPresented.has(property.name) && property.optional) {
                state.dataPresented.set(property.name, false);
            }
        }
        return (<LoggedPage {...props}>
            <div className={"clearfix"}>
                <Button className={`float-left ${styles.backButton}`}
                    onClick={() => history.push(`/${props.model.name}`)}>
                    <FontAwesomeIcon icon={faArrowLeft}/></Button>
                <h2 className={"float-left"}>Добавление {props.model.crudCaseTitle}</h2>
            </div>
            <div className={styles.formBody}>
                <div>
                    {props.model.properties.map(property => getControl({
                        ...props,
                        entityPropertyGetter: key => state.data.get(key),
                        property,
                        valueChangeCallback: value => runInAction(() => state.data.set(property.name, value)),
                        valueValidityCallback: valid => runInAction(() => state.dataValid.set(property.name, valid)),
                        propertyValue: state.data.get(property.name),
                        valuePresented: state.dataPresented.get(property.name) ?? false,
                        valueValid: state.dataValid.get(property.name) ?? false,
                        valuePresentedChangeCallback: presented => runInAction(() =>
                            state.dataPresented.set(property.name, presented)),
                        showValid: true
                    }))}
                </div>
                <div className={"clearfix"}>
                    <Button variant={"success"} className={"float-right"} disabled={state.isCreating ||
                    Array.from(state.dataValid.keys()).filter(key => !state.dataPresented.has(key)
                        || state.dataPresented.get(key)).map(key => state.dataValid.get(key)).indexOf(false) !== -1}
                    onClick={() => {
                        state.isCreating = true;
                        (props.model as CreatableModel).endpoints.create(state.viewData()).then(() => {
                            history.push(`/${props.model.name}`);
                        }).catch(e => {
                            state.isCreating = false;
                            props.errorHandler(e);
                        });
                    }}>Добавить</Button>
                </div>
            </div>
        </LoggedPage>);
    }}</Observer>;
}
