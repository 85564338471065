import {RequestHandlingError} from "../api/API";

const ERROR_TRANSLATION: Record<string, string> = {
    "unauthorized": "Не авторизован",
    "internal-server-error": "Внутренняя ошибка сервера",
    "wrong username or password": "Неправильный логин или пароль"
};

export function errorToString(error: unknown): string {
    const errorText = error instanceof RequestHandlingError ? (error.error ?
        (ERROR_TRANSLATION[error.error] ?? error.error) : "Неизвестная ошибка") :
        (error instanceof Error ? error.message : String(error));
    return `Ошибка: ${errorText}`;
}
