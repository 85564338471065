import React from "react";
import {GlobalState} from "../../../App";
import {ModelDefinition} from "../../../api/API";
import {LoggedPage} from "../../loggedPage/LoggedPage";

interface HomePageProps {
    globalState: GlobalState;
    models: ModelDefinition[];
}

export function HomePage(props: HomePageProps): JSX.Element {
    return (<LoggedPage {...props}><h1>GreenCity admin</h1></LoggedPage>);
}
