import axios, {Method} from "axios";
import * as querystring from "querystring";
import {ComponentClass, FunctionComponent, ReactNode} from "react";
import {GlobalState} from "../App";
import { ControlProps } from "../components/pages/model/ModelCreatePage";

type CustomPageProps = {
    globalState: GlobalState;
    models: ModelDefinition[];
    api: API;
    model: ModelDefinition;
    errorHandler: (error: Error) => void;
};

export type CustomCreatePageProps = unknown & CustomPageProps;

export type CustomUpdatePageProps = {
    id: string
} & CustomPageProps;

export interface CreatableModel {
    operations: {
        create: true;
    };
    endpoints: {
        create: (data: unknown) => Promise<unknown & DefaultModelData>;
    };
    customPages?: {
        create?: (props: CustomCreatePageProps) => ReactNode;
    };
}

export interface UpdatableModel {
    operations: {
        update: true;
    };
    endpoints: {
        update: (id: string, data: unknown) => Promise<unknown & DefaultModelData>;
    };
    customPages?: {
        update?: (props: CustomUpdatePageProps) => ReactNode;
    };
}

export interface DeletableModel {
    operations: {
        delete: true;
    };
    endpoints: {
        delete: (id: string) => Promise<void>;
    };
}

export interface SearchableModel {
    operations: {
        search: true;
    };
    searchProperties: ModelPropertyDefinition[];
    endpoints: {
        search: (data: unknown, skip: number, take: number) =>
            Promise<{ count: number, items: (unknown & DefaultModelData)[] }>
    }
}

interface NonCreatableModel {
    operations: {
        create?: false;
    };
    endpoints: unknown;
}

interface NonUpdatableModel {
    operations: {
        update?: false;
    };
    endpoints: unknown;
}

interface NonDeletableModel {
    operations: {
        delete?: false;
    };
    endpoints: unknown;
}

interface NonSearchableModel {
    operations: {
        search?: false;
    };
    endpoints: unknown;
}

interface SimpleModelDefinition {
    name: string;
    title: string;
    multipleTitle: string;
    crudCaseTitle: string;
    descriptionGenerator: (data: any) => ReactNode | ReactNode[] | string;
    operations: unknown;
    properties: ModelPropertyDefinition[];
    endpoints: {
        fetchSingle: (id: string) => Promise<unknown & DefaultModelData>;
        fetchMany: (skip: number, take: number) => Promise<{ count: number, items: (unknown & DefaultModelData)[] }>;
    };
}

export interface DefaultModelData {
    id: string;
}

export type ModelDefinition =
    SimpleModelDefinition
    & (NonCreatableModel | CreatableModel)
    & (NonUpdatableModel | UpdatableModel)
    & (NonDeletableModel | DeletableModel)
    & (NonSearchableModel | SearchableModel);

type ModelPropertyType = "image" | "imageArray" | "boolean" | "datetime" | "visualEditor" | "file";

export type ModelPropertyDefinition = {
    name: string;
    optional?: boolean;
    title: string;
    readonly?: boolean;
    originalModelPropertyName?: string;
} & ({
    type: ModelPropertyType;
} | {
    type: "otherSelector";
    otherModelName: string;
    otherModelMapper: (data: any) => string;
    originalModelPropertyName: string;
    filter: (entity: any, variant: any) => boolean;
} | {
    type: "string"
    unit?: string;
    maxLength?: number;
} | {
    type: "richString" | "time"
    unit?: string;
    maxLength?: number;
} | {
    type: "number";
    unit?: string;
    min?: number;
    max?: number;
} | {
    type: "numberMap";
    keyUnit?: string;
    valueUnit?: string;
} | {
    type: "variantSelect",
    variants: string[],
    mapper: (key: string) => string | undefined
} | {
    type: "custom",
    customProperty: FunctionComponent<ControlProps> | ComponentClass<ControlProps>
});

const backendUrl = process.env.REACT_APP_BACKEND_URL || "https://green-city-backend-develop.gitlab-stage.finwintech.com";

export class RequestHandlingError extends Error {
    constructor(public readonly code: number, public readonly error?: string) {
        super();
    }
}

type APIResponse = {
    success: true;
    data: unknown;
} | {
    success: false;
    error: string;
}

interface AuthResponse {
    token: string;
}

export class API {
    public static readonly MAX_TAKE_SIZE: number = 20;

    constructor(private session: string | null) {
    }

    setSession(session: string | null): string | null {
        return this.session = session;
    }

    async callApi(method: Method, endpoint: string, data?: unknown): Promise<unknown> {
        let response: APIResponse;
        try {
            const headers: Record<string, string> = {};
            if (this.session) {
                headers["Authorization"] = this.session;
            }
            response = (await axios.request({
                method: method,
                url: `${backendUrl}${endpoint}`,
                data: data,
                headers: headers
            })).data as APIResponse;
        } catch (e) {
            if (!e.response) {
                throw e;
            }
            throw new RequestHandlingError(e.response.status, e.response.data?.error);
        }
        if (response.success) {
            return response.data;
        }
        throw new RequestHandlingError(200, response.error);
    }

    async auth(username: string, password: string): Promise<string> {
        const response = await this.callApi("POST", "/api/admin/auth", {
            username,
            password
        }) as AuthResponse;
        return response.token;
    }

    getSingleFetcher<T = unknown>(model: string): (id: string) => Promise<T> {
        return async (id: string) => {
            return await this.callApi("GET", `/api/admin/${model}/${id}`) as T;
        };
    }

    getManyFetcher<T = unknown>(model: string): (skip: number, take: number) =>
        Promise<{ count: number, items: T[] }> {
        return async (skip: number, take: number) => {
            return (await this.callApi("GET", `/api/admin/${model}?${querystring.encode({
                offset: skip,
                count: take
            })}`)) as { count: number, items: T[] };
        };
    }

    getSearcher<T = unknown>(model: string): (data: unknown, skip: number, take: number) =>
        Promise<{ count: number, items: T[] }> {
        return async (data: unknown, skip: number, take: number) => {
            return (await this.callApi("POST", `/api/admin/${model}/search/?${querystring.encode({
                offset: skip,
                count: take
            })}`, data)) as { count: number, items: T[] };
        };
    }

    getDeleter(model: string): (id: string) => Promise<void> {
        return async (id: string) => {
            await this.callApi("DELETE", `/api/admin/${model}/${id}`);
        };
    }

    getCreator<T = unknown, ModelData = unknown>(model: string): (data: ModelData) => Promise<T> {
        return async (data: ModelData) => {
            return await this.callApi("POST", `/api/admin/${model}`, data) as T;
        };
    }

    getUpdater<T = unknown, ModelData = unknown>(model: string): (id: string, data: ModelData) => Promise<T> {
        return async (id: string, data: ModelData) => {
            return await this.callApi("PATCH", `/api/admin/${model}/${id}`, data) as T;
        };
    }
}


