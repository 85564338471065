import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "tinymce/tinymce";

import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/skins/ui/oxide/content.inline.css";

import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/code";
import "tinymce/plugins/help";
import "tinymce/plugins/wordcount";

import "bootstrap/dist/css/bootstrap.min.css";
import {API, DefaultModelData, ModelDefinition} from "./api/API";
import {ProductPropertiesProperty} from "./components/custom/ProductPropertiesProperty";
import {contentNameMapper, ContentUpdatePage} from "./components/custom/ContentUpdatePage";
import ProductsCategory from "./components/custom/ProductsCategory";

export const MAIN_CURRENCY_SIGN = "₽";

interface AdministratorData {
    username: string;
}

interface DefaultDocumentData {
    id: string;
}

interface CategoryData {
    name: string;
    primary: boolean;
    slug: string;
    isParent: boolean;
    description: string,
    fullDescription: string,
    seoTitle: string,
}

export interface FeedbackData {
    name: string;
    email: string;
    text: string;
    city?: string;
    createdOn: Date;
    visible: boolean;
}

export interface ContentData {
    data: string;
}

export interface CertificateData {
    imageUrl: string;
    altText: string;
    imageUrls: string[];
}

export interface ArticleCategoryData {
    name: string;
}

export interface ArticleData {
    category: ArticleCategoryData & DefaultDocumentData;
    title: string;
    subTitle: string;
    content: string;
    mainImageUrl: string;
    imageUrls: string[]
}

export interface ProjectData {
    title: string;
    subTitle: string;
    content: string;
    mainImageUrl: string;
    imageUrls: string[];
}

export interface ClientData {
    title: string;
    imageUrl: string;
}

const api = new API(null);

export interface SaleData {
    imageUrl: string;
    title: string;
    subTitle: string;
    description: string,
    type: "nPlus1" | "percent";
    endTime: Date;
    value?: number;
}

export interface OfficeData {
    region: string;
    address: string;
    phone: string;
    email: string;
    openTime: string;
    closeTime: string;
    offDays: string;
    mapLink?: string;
    lat?: number;
    lon?: number;
    messengerPhone?: string;
    mapImageUrl?: string;
    photoUrl?: string;
}

export interface ProductData {
    category: Array<CategoryData & DefaultDocumentData>,
    subCategory: Array<SubCategoryData & DefaultDocumentData>,
    subSubCategory: Array<SubSubCategoryData & DefaultDocumentData>,
    name: string,
    description: string,
    price: number,
    sale?: SaleData,
}

export interface SubCategoryData {
    parentCategory: (CategoryData & DefaultDocumentData) | (SubCategoryData & DefaultDocumentData),
    name: string,
    imageUrl: string,
    description: string,
    fullDescription: string,
    children: SubCategoryData[],
    seoTitle: string,
}

export interface SubSubCategoryData {
    name: string,
    imageUrl: string,
    description: string,
    fullDescription: string,
    subCategory: SubCategoryData & DefaultDocumentData,
    seoTitle: string,
}

export interface PriorityCategoryData {
    name: string;
    priority: number;
}

export interface PromotionData {
    title: string;
    subTitle?: string;
    imageUrl: string;
}

const saleTypes = {
    "nPlus1": "N + 1",
    "percent": "% от стоимости",
    "simple": "Промо-акция"
} as Record<string, string>;

const models: ModelDefinition[] = [
    {
        name: "categories",
        title: "Категория",
        multipleTitle: "Категории",
        crudCaseTitle: "категории",
        descriptionGenerator: (data: CategoryData) => `${data.name}${data.primary ? " - отображается в списке" : ""}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "boolean",
                name: "primary",
                title: "Отображается в заголовке?"
            },
            {
                type: "string",
                name: "slug",
                title: "Семантический URL",
            },
            {
                type: "string",
                name: "seoTitle",
                title: "SEO заголовок",
            },
            {
                type: "boolean",
                name: "isParent",
                title: "Это родительская категория",
            },
            {
                type: "richString",
                name: "description",
                title: "Описание"
            },
            {
                type: "richString",
                name: "fullDescription",
                title: "Полное описание"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("categories"),
            fetchMany: api.getManyFetcher("categories"),
            create: api.getCreator("categories"),
            update: api.getUpdater("categories"),
            delete: api.getDeleter("categories")
        },
    },
    {
        name: "sub-categories",
        title: "Подкатегория",
        multipleTitle: "Подкатегории",
        crudCaseTitle: "подкатегории",
        descriptionGenerator: (data: SubCategoryData) => `${data.parentCategory.name}/${data.name}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "otherSelector",
                name: "parentCategoryId",
                title: "Родительская категория",
                otherModelName: "categories",
                otherModelMapper: (category: CategoryData) => category.name,
                originalModelPropertyName: "parentCategory",
                filter: () => true
            },
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "string",
                name: "slug",
                title: "Семантический URL",
            },
            {
                type: "string",
                name: "seoTitle",
                title: "SEO заголовок",
            },
            {
                type: "image",
                name: "imageUrl",
                title: "Изображение"
            },
            {
                type: "richString",
                name: "description",
                title: "Описание"
            },
            {
                type: "richString",
                name: "fullDescription",
                title: "Полное описание"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("sub-categories"),
            fetchMany: api.getManyFetcher("sub-categories"),
            create: api.getCreator("sub-categories"),
            update: api.getUpdater("sub-categories"),
            delete: api.getDeleter("sub-categories")
        }
    },
    {
        name: "sub-sub-categories",
        title: "Подподкатегория",
        multipleTitle: "Подподкатегории",
        crudCaseTitle: "подподкатегории",
        descriptionGenerator: (data: SubSubCategoryData) => `${data?.subCategory?.name}/${data?.name}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "otherSelector",
                name: "parentSubCategoryId",
                title: "Родительская категория",
                otherModelName: "sub-categories",
                otherModelMapper: (category: SubCategoryData) => category.name,
                originalModelPropertyName: "subCategory",
                filter: () => true
            },
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "string",
                name: "slug",
                title: "Семантический URL",
            },
            {
                type: "string",
                name: "seoTitle",
                title: "SEO заголовок",
            },
            {
                type: "image",
                name: "imageUrl",
                title: "Изображение"
            },
            {
                type: "richString",
                name: "description",
                title: "Описание"
            },
            {
                type: "richString",
                name: "fullDescription",
                title: "Полное описание"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("sub-sub-categories"),
            fetchMany: api.getManyFetcher("sub-sub-categories"),
            create: api.getCreator("sub-sub-categories"),
            update: api.getUpdater("sub-sub-categories"),
            delete: api.getDeleter("sub-sub-categories")
        }
    },
    {
        name: "priority-categories",
        title: "Приоритетная категория",
        multipleTitle: "Приоритетные категории",
        crudCaseTitle: "приоритетной категории",
        descriptionGenerator: (data: PriorityCategoryData) => `${data.name} - ${data.priority}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "number",
                name: "priority",
                title: "Приоритет (больше - выше)"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("priority-categories"),
            fetchMany: api.getManyFetcher("priority-categories"),
            create: api.getCreator("priority-categories"),
            update: api.getUpdater("priority-categories"),
            delete: api.getDeleter("priority-categories")
        },
    },
    {
        name: "products",
        title: "Товар",
        multipleTitle: "Товары",
        crudCaseTitle: "товара",
        descriptionGenerator: (data: ProductData) => `${data.name}`,
        operations: {
            create: true,
            update: true,
            delete: true,
            search: true
        },
        searchProperties: [
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "otherSelector",
                name: "categoryId",
                title: "Категория",
                originalModelPropertyName: "category",
                otherModelName: "categories",
                optional: true,
                otherModelMapper: (category: CategoryData) => category.name,
                filter: () => true
            }
        ],
        properties: [
            {
                type: "string",
                name: "slug",
                title: "Семантический URL"
            },
            {
                type: "string",
                name: "name",
                title: "Название"
            },
            {
                type: "number",
                name: "price",
                title: "Цена (0 для \"по запросу\")",
                unit: MAIN_CURRENCY_SIGN,
                min: 0
            },
            {
                type: "richString",
                name: "description",
                title: "Описание"
            },
            {
                type: "custom",
                name: "category",
                originalModelPropertyName: "categories",
                title: "Категория",
                optional: true,
                customProperty: ProductsCategory,
            },
            {
                type: "custom",
                name: "subCategory",
                title: "Подкатегория",
                optional: true,
                originalModelPropertyName: "sub-categories",
                customProperty: ProductsCategory,
            },
            {
                type: "custom",
                name: "subSubCategory",
                title: "Подподкатегория",
                optional: true,
                originalModelPropertyName: "sub-sub-categories",
                customProperty: ProductsCategory,
            },
            {
                type: "otherSelector",
                name: "priorityCategoryId",
                title: "Приоритетная категория",
                optional: true,
                originalModelPropertyName: "priorityCategory",
                otherModelName: "priority-categories",
                otherModelMapper: (priorityCategory: PriorityCategoryData) =>
                    `${priorityCategory.name}: ${priorityCategory.priority}`,
                filter: () => true
            },
            {
                type: "otherSelector",
                name: "saleId",
                title: "Акция",
                optional: true,
                originalModelPropertyName: "sale",
                otherModelName: "sales",
                otherModelMapper: (sale: SaleData) => `${sale.title} - ${sale.subTitle}`,
                filter: () => true
            },
            {
                type: "custom",
                name: "properties",
                title: "Свойства",
                customProperty: ProductPropertiesProperty
            },
            {
                type: "boolean",
                name: "disabled",
                title: "Скрыт?",
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("products"),
            fetchMany: api.getManyFetcher("products"),
            create: api.getCreator("products"),
            update: api.getUpdater("products"),
            delete: api.getDeleter("products"),
            search: api.getSearcher("products")
        },
    },
    {
        name: "sales",
        title: "Акция",
        multipleTitle: "Акции",
        crudCaseTitle: "акции",
        // eslint-disable-next-line react/display-name
        descriptionGenerator: (data: SaleData) => (
            <>
                {`${data.title} до ${new Date(data.endTime).toLocaleString()} - ${saleTypes[data.type]}`}
                <p style={{ fontSize: "0.75em" }}>{data.subTitle}</p>
            </>
        ),
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "title",
                title: "Название",
                maxLength: 5,
            },
            {
                type: "string",
                name: "subTitle",
                title: "Подзаголовок"
            },
            {
                type: "richString",
                name: "description",
                title: "Описание"
            },
            {
                type: "variantSelect",
                name: "type",
                title: "Тип",
                variants: Object.keys(saleTypes),
                mapper: (key: string) => saleTypes[key]
            },
            {
                type: "datetime",
                name: "endTime",
                title: "Время окончания"
            },
            {
                type: "number",
                name: "value",
                title: "Скидка в % или число в N + 1",
                optional: true
            },
            {
                type: "image",
                name: "imageUrl",
                title: "Изображение",
            },
            {
                type: "boolean",
                name: "disabled",
                title: "Отключена?"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("sales"),
            fetchMany: api.getManyFetcher("sales"),
            create: api.getCreator("sales"),
            update: api.getUpdater("sales"),
            delete: api.getDeleter("sales")
        },
    },
    {
        name: "promotions",
        title: "Промоакция",
        multipleTitle: "Промоакции",
        crudCaseTitle: "промоакции",
        descriptionGenerator: (data: PromotionData) => data.title,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "title",
                title: "Название"
            },
            {
                type: "string",
                name: "subTitle",
                optional: true,
                title: "Подзаголовок"
            },
            {
                type: "image",
                name: "imageUrl",
                title: "Изображение"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("promotions"),
            fetchMany: api.getManyFetcher("promotions"),
            create: api.getCreator("promotions"),
            update: api.getUpdater("promotions"),
            delete: api.getDeleter("promotions")
        },
    },
    {
        name: "feedbacks",
        title: "Отзыв",
        multipleTitle: "Отзывы",
        crudCaseTitle: "отзыва",
        descriptionGenerator: (data: FeedbackData) => `${data.name}, ${data.email}, ${
            data.city ?? "без города"} - ${new Date(data.createdOn).toLocaleString()}${data.visible ? "" : " - скрыт"}`,
        operations: {
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "name",
                title: "Имя"
            },
            {
                type: "string",
                name: "email",
                title: "E-Mail"
            },
            {
                type: "string",
                name: "city",
                title: "Город",
                optional: true
            },
            {
                type: "richString",
                name: "text",
                title: "Текст"
            },
            {
                type: "boolean",
                name: "visible",
                title: "Опубликован"
            },
            {
                type: "datetime",
                name: "createdOn",
                title: "Дата публикации",
                readonly: true
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("feedbacks"),
            fetchMany: api.getManyFetcher("feedbacks"),
            update: api.getUpdater("feedbacks"),
            delete: api.getDeleter("feedbacks")
        },
    },
    {
        name: "contents",
        title: "Содержимое страницы",
        multipleTitle: "Содержимое страниц",
        crudCaseTitle: "содержимого страницы",
        descriptionGenerator: (data: ContentData & DefaultModelData) => contentNameMapper(data.id),
        operations: {
            update: true
        },
        properties: [
            {
                type: "visualEditor",
                name: "data",
                title: "Текст"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("contents"),
            fetchMany: api.getManyFetcher("contents"),
            update: api.getUpdater("contents"),
        },
        customPages: {
            // eslint-disable-next-line react/display-name
            update: props => React.createElement(ContentUpdatePage, props)
        },
    },
    {
        name: "certificates",
        title: "Сертификат",
        multipleTitle: "Сертификаты",
        crudCaseTitle: "сертификата",
        descriptionGenerator: (data: CertificateData) => `${data.altText}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "image",
                name: "imageUrl",
                title: "Главное изображение"
            },
            {
                type: "string",
                name: "altText",
                title: "Текст"
            },
            {
                type: "imageArray",
                name: "imageUrls",
                title: "Изображения"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("certificates"),
            fetchMany: api.getManyFetcher("certificates"),
            create: api.getCreator("certificates"),
            update: api.getUpdater("certificates"),
            delete: api.getDeleter("certificates")
        },
    },
    {
        name: "article-categories",
        title: "Категория статьи",
        multipleTitle: "Категории статей",
        crudCaseTitle: "категории статьи",
        descriptionGenerator: (data: ArticleCategoryData) => `${data.name}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "name",
                title: "Название"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("article-categories"),
            fetchMany: api.getManyFetcher("article-categories"),
            create: api.getCreator("article-categories"),
            update: api.getUpdater("article-categories"),
            delete: api.getDeleter("article-categories")
        },
    },
    {
        name: "articles",
        title: "Статья",
        multipleTitle: "Статьи",
        crudCaseTitle: "статьи",
        descriptionGenerator: (data: ArticleData) => `${data.title} - ${data.category.name}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "otherSelector",
                name: "categoryId",
                title: "Категория",
                otherModelName: "article-categories",
                otherModelMapper: (data: ArticleCategoryData) => data.name,
                originalModelPropertyName: "category",
                filter: () => true
            },
            {
                type: "string",
                name: "slug",
                title: "Семантический URL"
            },
            {
                type: "string",
                name: "title",
                title: "Заголовок"
            },
            {
                type: "string",
                name: "subTitle",
                title: "Подзаголовок"
            },
            {
                type: "visualEditor",
                name: "content",
                title: "Текст"
            },
            {
                type: "image",
                name: "mainImageUrl",
                title: "Основное изображение"
            },
            {
                type: "imageArray",
                name: "imageUrls",
                title: "Дополнительные изображения"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("articles"),
            fetchMany: api.getManyFetcher("articles"),
            create: api.getCreator("articles"),
            update: api.getUpdater("articles"),
            delete: api.getDeleter("articles")
        }
    },

    {
        name: "projects",
        title: "Объект",
        multipleTitle: "Объекты",
        crudCaseTitle: "объекта",
        descriptionGenerator: (data: ProjectData) => data.title,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "slug",
                title: "Семантический URL"
            },
            {
                type: "string",
                name: "title",
                title: "Заголовок"
            },
            {
                type: "string",
                name: "subTitle",
                title: "Подзаголовок"
            },
            {
                type: "visualEditor",
                name: "content",
                title: "Текст"
            },
            {
                type: "image",
                name: "mainImageUrl",
                title: "Основное изображение"
            },
            {
                type: "imageArray",
                name: "imageUrls",
                title: "Дополнительные изображения"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("projects"),
            fetchMany: api.getManyFetcher("projects"),
            create: api.getCreator("projects"),
            update: api.getUpdater("projects"),
            delete: api.getDeleter("projects")
        },
    },
    {
        name: "clients",
        title: "Клиент",
        multipleTitle: "Клиенты",
        crudCaseTitle: "клиента",
        descriptionGenerator: (data: ClientData) => data.title,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "title",
                title: "Название"
            },
            {
                type: "image",
                name: "imageUrl",
                title: "Изображение"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("clients"),
            fetchMany: api.getManyFetcher("clients"),
            create: api.getCreator("clients"),
            update: api.getUpdater("clients"),
            delete: api.getDeleter("clients")
        },
    },
    {
        name: "offices",
        title: "Представительство",
        multipleTitle: "Представительства",
        crudCaseTitle: "представительства",
        descriptionGenerator: (data: OfficeData) => `${data.region} - ${data.email} - ${data.phone}`,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "variantSelect",
                name: "region",
                title: "Регион",
                variants: [
                    "Республика Адыгея (Адыгея)",
                    "Республика Башкортостан",
                    "Республика Бурятия",
                    "Республика Алтай",
                    "Республика Дагестан",
                    "Республика Ингушетия",
                    "Кабардино-Балкарская Республика",
                    "Республика Калмыкия",
                    "Карачаево-Черкесская Республика",
                    "Республика Карелия",
                    "Республика Коми",
                    "Республика Марий Эл",
                    "Республика Мордовия",
                    "Республика Саха (Якутия)",
                    "Республика Северная Осетия - Алания",
                    "Республика Татарстан (Татарстан)",
                    "Республика Тыва",
                    "Удмуртская Республика",
                    "Республика Хакасия",
                    "Чеченская Республика",
                    "Чувашская Республика - Чувашия",
                    "Алтайский край",
                    "Краснодарский край",
                    "Красноярский край",
                    "Приморский край",
                    "Ставропольский край",
                    "Хабаровский край",
                    "Амурская область",
                    "Архангельская область",
                    "Астраханская область",
                    "Белгородская область",
                    "Брянская область",
                    "Владимирская область",
                    "Волгоградская область",
                    "Вологодская область",
                    "Воронежская область",
                    "Ивановская область",
                    "Иркутская область",
                    "Калининградская область",
                    "Калужская область",
                    "Камчатский край",
                    "Кемеровская область - Кузбасс",
                    "Кировская область",
                    "Костромская область",
                    "Курганская область",
                    "Курская область",
                    "Ленинградская область",
                    "Липецкая область",
                    "Магаданская область",
                    "Московская область",
                    "Мурманская область",
                    "Нижегородская область",
                    "Новгородская область",
                    "Новосибирская область",
                    "Омская область",
                    "Оренбургская область",
                    "Орловская область",
                    "Пензенская область",
                    "Пермский край",
                    "Псковская область",
                    "Ростовская область",
                    "Рязанская область",
                    "Самарская область",
                    "Саратовская область",
                    "Сахалинская область",
                    "Свердловская область",
                    "Смоленская область",
                    "Тамбовская область",
                    "Тверская область",
                    "Томская область",
                    "Тульская область",
                    "Тюменская область",
                    "Ульяновская область",
                    "Челябинская область",
                    "Забайкальский край",
                    "Ярославская область",
                    "г. Москва",
                    "Санкт-Петербург",
                    "Еврейская автономная область",
                    "Ненецкий автономный округ",
                    "Ханты-Мансийский автономный округ - Югра",
                    "Чукотский автономный округ",
                    "Ямало-Ненецкий автономный округ",
                    "Республика Крым",
                    "Севастополь",
                    "Иные территории, включая город и космодром Байконур"
                ],
                mapper: (key: string) => key
            },
            {
                type: "string",
                name: "address",
                title: "Адрес"
            },
            {
                type: "string",
                name: "mapLink",
                title: "Ссылка на карту",
                optional: true
            },
            {
                type: "string",
                name: "phone",
                title: "Телефон"
            },
            {
                type: "string",
                name: "email",
                title: "E-Mail"
            },
            {
                type: "time",
                name: "openTime",
                title: "Время открытия"
            },
            {
                type: "time",
                name: "closeTime",
                title: "Время закрытия"
            },
            {
                type: "string",
                name: "offDays",
                title: "Выходные дни"
            },
            {
                type: "number",
                name: "lat",
                title: "Широта",
                optional: true
            },
            {
                type: "number",
                name: "lon",
                title: "Долгота",
                optional: true
            },
            {
                type: "image",
                name: "mapImageUrl",
                title: "Изображение карты",
                optional: true
            },
            {
                type: "image",
                name: "photoUrl",
                title: "Фотография",
                optional: true
            },
            {
                type: "string",
                name: "messengerPhone",
                title: "Телефон для мессенджеров (Viber/Telegram/WhatsApp)",
                optional: true
            },
            {
                type: "boolean",
                name: "isHead",
                title: "Главный офис"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("offices"),
            fetchMany: api.getManyFetcher("offices"),
            create: api.getCreator("offices"),
            update: api.getUpdater("offices"),
            delete: api.getDeleter("offices")
        },
    },
    {
        name: "administrators",
        title: "Администратор",
        multipleTitle: "Администраторы",
        crudCaseTitle: "администратора",
        descriptionGenerator: (data: AdministratorData) => data.username,
        operations: {
            create: true,
            update: true,
            delete: true
        },
        properties: [
            {
                type: "string",
                name: "username",
                title: "Логин"
            },
            {
                type: "string",
                name: "password",
                title: "Новый пароль"
            }
        ],
        endpoints: {
            fetchSingle: api.getSingleFetcher("administrators"),
            fetchMany: api.getManyFetcher("administrators"),
            create: api.getCreator("administrators"),
            update: api.getUpdater("administrators"),
            delete: api.getDeleter("administrators")
        },
    }
];

ReactDOM.render(
    <React.StrictMode>
        <App api={api} models={models}/>
    </React.StrictMode>,
    document.body.appendChild(document.createElement("div")),
);
