export function getSession(): string | null {
    return window.localStorage.getItem("session");
}

export function setSession(value: string | null): string | null {
    if (value) {
        window.localStorage.setItem("session", value);
    } else {
        window.localStorage.removeItem("session");
    }
    return window.localStorage.getItem("session");
}
