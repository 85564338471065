import React from "react";
import Navbar from "react-bootstrap/esm/Navbar";
import Container from "react-bootstrap/cjs/Container";
import {GlobalState} from "../../App";
import {setSession} from "../../utils/session";
import {Observer} from "mobx-react";
import Col from "react-bootstrap/cjs/Col";
import Row from "react-bootstrap/cjs/Row";
import ListGroup from "react-bootstrap/cjs/ListGroup";
import styles from "./LoggedPage.module.css";
import {ModelDefinition} from "../../api/API";
import {useLocation, useHistory} from "react-router-dom";
import {runInAction} from "mobx";

type LoggedPageProps = React.PropsWithChildren<{
    globalState: GlobalState;
    models: ModelDefinition[];
}>;

export function LoggedPage(props: LoggedPageProps): JSX.Element {
    const location = useLocation();
    const history = useHistory();

    return (<Observer>{() => (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/">GreenCity admin</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <a onClick={(e) => {
                                e.preventDefault();
                                runInAction(() => {
                                    props.globalState.session = setSession(null);
                                });
                            }} href={"/logout"}>Выйти</a>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className={styles.contentBody}>
                <Row>
                    <Col>
                        <ListGroup>
                            {props.models.map(model =>
                                <ListGroup.Item action
                                    key={`${model.name}-select-button`}
                                    active={location.pathname === `/${model.name}` ||
                                                location.pathname.startsWith(`/${model.name}/`)}
                                    onClick={() => {
                                        history.push(`/${model.name}`);
                                    }}>
                                    {model.multipleTitle}
                                </ListGroup.Item>)}
                        </ListGroup>
                        <ListGroup style={{marginTop: "20px"}}>
                            <ListGroup.Item action
                                active={location.pathname === "/settings"}
                                onClick={() => {
                                    history.push("/settings");
                                }}>
                                    Настройки
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={9} style={{marginBottom: "100px"}}>{props.children}</Col>
                </Row>
            </Container>
        </>
    )}</Observer>);
}
